.voice-call-modal {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .modal-body {
    padding-right: 0;
  }

  .scrolling-content:first-child {
    padding-right: 20px;
  }

  .modal-content {
    min-height: 450px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    border: none;
    padding: 20px;
  }

  .modal-header {
    border-bottom: none;
    position: relative;

    .close-icon {
      position: absolute;
      top: -16px;
      right: -12px;
      cursor: pointer;
    }
  }

  .modal-footer {
    border-top: none;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .search-wrapper-outer {
    display: flex;
  }

  .search-detail p {
    color: #707070;
    font-size: 14px;
    margin-top: 30px;
  }

  .message-tab {
    border-right: 0;
    padding-right: 20px;

    .message-single {
      background-color: #fff;
      padding: 25px 0 0;
    }

    .icon {
      display: flex;

      i {
        font-size: 20px;
        color: $primary;
      }

      svg {
        width: 17px;
        height: 19px;
      }

      path {
        fill: $primary;
      }

      .user-icon {
        margin-right: 10px;

        svg {
          height: 19px;
          width: 17px;
        }

        path {
          fill: #b4b4b4;
        }
      }
    }
  }
}

.new-call-modal {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    min-height: 450px;
    padding: 20px;

    .search-filter {
      background: #fff !important;

      &.has-search {
        background-color: #fff;
        border-color: $primary;
      }

      .tab-title {
        font-size: 14px;
        color: #707070;
      }
    }
  }

  .modal-header {
    border-bottom: none;
    position: relative;

    .close-icon {
      position: absolute;
      right: -8px;
      top: -15px;
    }
  }

  .modal-footer {
    border-top: none;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .dial-pad {
    padding: 20px;

    .single {
      align-items: center;
      border: 1px solid #122b28;
      border-radius: 50%;
      display: flex;
      height: 55px;
      justify-content: center;
      width: 55px;
      flex-direction: column;
      margin-bottom: 10px;

      &:hover {
        background-color: #36c02b;
        border-color: #36c02b;

        .number {
          color: #fff;
        }

        .alphabet {
          color: #fff;
        }
      }

      .number {
        color: #122b28;
        font-size: 30px;
        font-weight: 300;
        line-height: 1;
        margin: -2px 0 2px;
      }

      .alphabet {
        color: #122b28;
        font-size: 9px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
      }
    }

    .dial {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 55px;
      width: 55px;
      background-color: #36c02b;
      margin-top: 8px;

      i {
        color: #fff;
        font-size: 35px;
      }
    }
  }
}

.making-call {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    min-height: 450px;
    text-align: center;
  }

  .modal-header {
    border-bottom: none;
    position: relative;

    .close-icon {
      position: absolute;
      right: 8px;
      top: 6px;
    }
  }

  .modal-body {
    .avatar {
      width: 75px;
      height: 75px;
      font-size: 35px;
      margin-bottom: 10px;
      margin-right: 0;

      &.calling {
        height: 80px;
        width: 80px;
        font-size: 40px;
      }
    }
  }

  .modal-footer {
    border-top: none;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #122b28;
  }

  .incomming-title {
    font-size: 20px;
    font-weight: 500;
    color: #122b28;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .contact-info {
    align-items: center;
    display: flex;
    flex-direction: column;

    .calling {
      margin-top: 15px;

      span {
        font-size: 20px;
        color: #122b28;
      }
    }

    span {
      font-size: 16px;
      color: #122b28;

      &.name {
        font-weight: 500;
      }
    }
  }

  .time {
    margin: 30px 0;
    font-size: 20px;
    color: #122b28;
  }

  .number-select {
    margin-top: 32px;
    margin-bottom: 10px;

    span {
      font-size: 13px;
      font-weight: 300;
      color: #122b28;
    }

    select {
      border: none;
      font-size: 13px;
      margin-bottom: 22px;
      color: #122b28;

      &:focus {
        border: none;
      }
    }
  }

  .credit-info {
    font-size: 12px;
    font-weight: 300;
    color: #122b28;
  }

  .dial {
    align-items: center;
    background-color: #36c02b;
    border-radius: 50%;
    display: inline-flex;
    height: 55px;
    justify-content: center;
    margin-top: 30px;
    width: 55px;

    i {
      color: #fff;
      font-size: 35px;
    }
  }

  .decline {
    align-items: center;
    background-color: #dd0000;
    border-radius: 50%;
    display: inline-flex;
    height: 55px;
    justify-content: center;
    margin-top: 30px;
    width: 55px;

    i {
      color: #fff;
      font-size: 35px;
    }
  }
}

.new-call-with-number {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    min-height: 450px;
    padding: 20px;

    .search-filter {
      background: #fff !important;

      &.has-search {
        background-color: #fff;
        border-color: $primary;
      }

      .tab-title {
        font-size: 16px !important;
        color: #122b28;
      }
    }
  }

  .modal-header {
    border-bottom: none;
    position: relative;

    .close-icon {
      position: absolute;
      right: -8px;
      top: -15px;
    }
  }

  .modal-footer {
    border-top: none;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .show-contact {
    align-items: center;
    display: flex;

    .name {
      color: #122b28;
      font-size: 16px;
      margin-bottom: 0;
    }

    .search {
      margin-bottom: 8px;
      margin-left: auto;
    }
  }

  .entered-number {
    padding: 50px 30px 30px;

    span {
      color: #122b28;
      font-size: 24px;
    }
  }

  .dial-pad {
    padding: 0 20px 20px;

    .single {
      align-items: center;
      border: 1px solid #122b28;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      height: 55px;
      justify-content: center;
      margin-bottom: 10px;
      width: 55px;

      &:hover {
        background-color: #36c02b;
        border-color: #36c02b;

        .number {
          color: #fff;
        }

        .alphabet {
          color: #fff;
        }
      }

      .number {
        color: #122b28;
        font-size: 30px;
        font-weight: 300;
        line-height: 1;
        margin: -2px 0 2px;
      }

      .alphabet {
        color: #122b28;
        font-size: 9px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
      }
    }

    .dial {
      align-items: center;
      background-color: #36c02b;
      border-radius: 50%;
      display: flex;
      height: 55px;
      justify-content: center;
      margin-top: 8px;
      width: 55px;

      i {
        color: #fff;
        font-size: 35px;
      }
    }

    .clear {
      align-items: center;
      display: flex;
      height: 55px;
      justify-content: center;
      margin-top: 5px;
      width: 55px;
    }
  }
}
