.message-tab {
  border-right: 1px solid #e1e1e1;
  overflow: hidden;

  .dropdown-menu {
    line-height: 1.5 !important;
  }

  .message-unread-info {
    align-items: center;
    border-bottom: 2px solid #e7e7e7;
    display: flex;
    font-size: 0.81rem;
    font-weight: 400;
    height: 34px;
    margin-top: 46px;
    padding: 0 15px;
    top: 0;
    z-index: 9;

    a {
      color: #122b28;
    }

    .no-unread {
      text-align: center;
      width: 100%;
    }
  }

  .message-list {
    background: none;
    border-top: 0;
    border-bottom: 0;
    padding-bottom: 60px;
  }

  .message-single {
    background-color: #f8f8f8;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    display: flex;
    padding: 13px 15px;

    &:hover {
      background-color: #fff;
    }

    &.active {
      background: #fff;
      border-bottom: 1px solid $primary-light;
      border-top: 1px solid $primary-light;
      color: #707070;
    }

    &.selected {
      background: #fff;
      border-bottom: 1px solid $primary-light;
      border-top: 1px solid $primary-light;
    }

    &.new-contact {
      align-items: center;

      .action {
        display: flex;

        a {
          line-height: 0;
          margin-left: 10px;
        }

        i {
          color: $primary-light;
          font-size: 20px;
        }

        svg {
          path {
            fill: $primary-light;
          }
        }
      }
    }

    &.contact-tab-single {
      align-items: center;

      &.with-detail {
        align-items: flex-start;

        .message-text {
          display: block;
          font-size: 13px;
          color: #707070;
        }

        .info {
          display: flex;
        }

        .detail {
          flex-direction: column;
        }

        .detail-single {
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;

          .message-text {
            margin-top: 0;

            &.number {
              color: #122b28;
              font-size: 13px !important;
            }
          }

          .info .phone-icon,
          .optedout-icon svg {
            max-height: 12px;
          }
        }
      }

      .name {
        color: #122b28;

        .duplicate {
          opacity: 0.5;
        }
      }

      .message-text {
        display: none;
      }

      .info {
        align-items: center;
        display: none;

        .status-icon {
          display: inline-flex;
          margin-right: 10px;

          &.group-icon {
            svg {
              width: 17px;
            }
          }

          &.optedout-icon {
            svg {
              width: 15px;
            }
          }

          &.phone-icon {
            svg {
              width: 8px;
            }
          }

          svg {
            width: 16px;

            path {
              fill: #b4b4b4;
            }
          }
        }
      }
    }

    h3.name {
      display: flex;
      justify-content: flex-start;
      padding-top: 0;
    }

    &.group {
      align-items: center;
      padding: 13px 20px;
      border-bottom: none;

      .name {
        color: #122b28;
      }

      .number {
        align-items: center;
        color: #122b28;
        display: inline-flex;
        font-size: 16px;
        justify-content: center;
        margin-left: 15px;
        margin-top: 0;
      }

      .total-number {
        color: #b4b4b4;
        font-size: 16px;
        margin-left: 10px;
      }

      .icon {
        line-height: 0;
        margin-left: auto;
        min-width: 24px;
        text-align: right;

        img {
          opacity: 0.5;
        }

        &.info {
          display: flex;

          svg {
            width: 14px;
            max-height: 14px;

            path {
              fill: #b4b4b4;
            }

            rect {
              fill: #b4b4b4;
            }
          }
        }
      }
    }

    &.unread {
      .message-text {
        font-weight: 600;
        color: #122b28;

        svg path {
          fill: #122b28;
        }
      }

      .name {
        font-size: 1rem;
        font-weight: 600;
        color: #122b28;
      }

      h3 {
        display: flex;
        margin: 0;
        font-size: 1rem;
        align-items: center;
        justify-content: flex-start;

        svg path {
          fill: #122b28;
        }
      }
    }

    &.undelivered {
      h3 {
        display: flex;
        margin: 0;
        font-size: 1rem;
        align-items: center;
        justify-content: flex-start;

        .undelivered {
          font-size: 1rem;
          color: $secondary;

          &.cleared {
            color: #dbdbdb;
          }
        }
      }
    }

    &.my-contact {
      display: flex;
      align-items: center;
      border-bottom: none;
      padding: 13px 20px;

      &.pressed {
        .info {
          display: flex;
        }
      }

      .info {
        display: none;
        align-items: center;
        margin-top: 8px;

        .number {
          margin-top: 0;
        }

        .status-icon {
          margin-left: 10px;

          img {
            vertical-align: middle;
          }
        }
      }

      .icon {
        img {
          vertical-align: middle;
        }
      }
    }

    .avatar {
      width: 36px;
      min-width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 10px;
      font-size: 1.06rem;
      color: #fff;
      font-weight: 400;
    }

    .detail {
      overflow: auto;
      overflow-y: hidden;
      flex-grow: 1;
    }

    h3 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      padding-top: 3px;
      line-height: 1;
      white-space: nowrap;

      .date {
        font-size: 0.81rem;
        display: flex;
        align-items: center;
        color: #707070;
        margin-top: 1px;
        font-weight: 400;
        margin-left: auto;

        i {
          font-size: 0.75rem;
          margin-right: 5px;
        }
      }

      svg path {
        fill: #707070;
      }
    }

    .name {
      margin: 0;
      color: #707070;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      i {
        color: #707070;
        font-size: 1rem;
        margin-left: 5px;
        margin-top: -2px;
        vertical-align: middle;
      }

      .icon {
        margin-left: 5px;
      }
    }

    .number {
      margin: 0;
      font-weight: 300;
      font-size: 0.94rem;
      margin-top: 8px;
      color: $color-text;
    }

    .message-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        display: flex;
        align-items: center;
        margin-top: 6px;

        i,
        span {
          margin-left: 10px;
        }

        i {
          font-size: 0.75rem;
        }

        .unread-noti {
          font-weight: 600;
          font-size: 0.81rem;
          height: 16px;
          width: 16px;
          background-color: #9253ff;
          color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .message-text {
      margin: 0;
      color: #707070;
      font-size: 0.81rem !important;
      margin-top: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      display: block;
      align-items: center;

      svg {
        margin-right: 5px;
        max-height: 14px;
        min-width: 15px;

        path {
          fill: #707070;
        }
      }

      .icon {
        vertical-align: middle;
        margin-right: 7px;
      }
    }
  }
}
