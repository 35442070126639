.search-filter.has-search {
  background: #e1e1e1;

  .search-wrapper-outer {
    display: flex;
    max-width: 100%;
  }

  .message-icon,
  .right-action,
  .sidenav-trigger,
  .tab-title,
  .user-action-single,
  .warning-icon {
    display: none;
  }
}

.search-wrapper-outer {
  display: none;
  align-items: center;
  flex-grow: 1;
  padding-right: 9px;

  .hide-search {
    color: #122b28;
    font-size: 0.81rem;
    margin-left: 10px;
  }

  .country-select-wrapper {
    margin-right: 5px;
    display: none;
    height: 42px;
    position: relative;

    &.active {
      display: flex;
    }

    select {
      background: none;
      border: none;
      outline: 0;
      color: #122b28;
      font-size: 0.81rem;
    }

    select::-ms-expand {
      display: none;
    }
  }
}

.search-wrapper {
  background: #fff;
  border: 1px solid #ddd;
  display: grid;
  align-items: center;
  height: 34px;
  padding: 0 10px;
  border-radius: 17px;
  flex-grow: 1;
  position: relative;

  &.focused {
    border-color: $primary;
  }

  &.has-value {
    .clear-icon {
      opacity: 1;
    }
  }

  input {
    border: none !important;
    background: none;
    padding-left: 5px;
    min-width: 1px;
    flex-grow: 1;
    margin-bottom: 0 !important;
    height: 32px;
    padding-right: 20px;

    &:focus {
      border-bottom: none !important;
      box-shadow: none !important;
    }
  }

  .search-action {
    display: flex;
    align-items: center;
  }

  .clear-icon {
    opacity: 0;
    font-size: 20px;
    color: #122b28;
    position: absolute;
    right: 10px;
  }

  .search-icon {
    font-size: 0.8125rem;
  }
}

.search-filter {
  align-items: center;
  background: #e1e1e1;
  display: flex;
  height: 45px;
  justify-content: center;
  left: 0;
  line-height: 0;
  padding: 0 15px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.4s ease-in-out;
  z-index: 999;

  &.header-mobile-app {
    background-color: $primary-light;

    .tab-title {
      color: white;
    }

    .filter-icon,
    .right-action {
      color: white;

      svg {
        path,
        circle {
          fill: white;
        }
      }
    }
  }

  &.no-fixed {
    transform: translate(0, -100%);
  }

  &.align-left {
    justify-content: flex-start;
  }

  .tab-title {
    color: #122b28;
    font-size: 1rem;
    font-weight: 500;
    margin-left: 0;
  }

  .filter-icon {
    color: #fff;
    font-size: 20px;
    line-height: 0;
    margin-left: 15px;

    svg {
      path {
        fill: #122b28;
      }
    }

    i {
      font-size: 21px;
    }
  }

  .right-action {
    align-items: center;
    display: flex;
    margin-left: auto;

    .dropdown-toggle {
      color: #122b28;
      font-size: 18px;
      margin-left: 6px;

      i {
        font-size: 18px;
      }

      &:after {
        display: none;
      }

      &:before {
        display: none;
      }
    }

    .dropdown-menu {
      border: 1px solid #707070;
      box-shadow: none;
      min-width: 200px;
      padding: 10px 0;

      li {
        a {
          align-items: center;
          border-radius: 0;
          color: #122b28;
          display: flex;
          font-size: 14px;
          font-weight: 500;
          padding-left: 20px;
          padding-right: 20px;

          &.active {
            background: #fff;
            box-shadow: none;
          }

          &:focus {
            background: #fff;
            box-shadow: none;
          }

          .icon {
            display: inline-block;
            height: 24px;
            margin-right: 10px;
            width: 24px;

            img {
              vertical-align: middle;
            }
          }
        }

        .show-hide-detail {
          .hide-detail {
            display: none;
          }

          &.active {
            .show-detail {
              display: none;
            }

            .hide-detail {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
