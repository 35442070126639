.manage-group-modal {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .warning {
    align-items: center;
    background: #fc7e00;
    border-radius: 5px 5px 0 0;
    color: #fff;
    display: none;
    font-size: 14px;
    left: 0;
    padding: 6px 16px;
    position: absolute;
    right: 0;
    top: -30px;
    white-space: nowrap;
    z-index: -1;
    @media (max-width: 600px) {
      padding: 6px 10px;
    }
    @media (max-width: 380px) {
      font-size: 10px;
    }

    &.active {
      display: flex;
    }

    .icon {
      margin-right: 10px;
      min-width: 16px;
      @media (max-width: 600px) {
        margin-right: 5px;
      }
    }
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    min-height: 450px;
  }

  .modal-body {
    max-height: 50vh;
    overflow: auto;
    @media (max-height: 680px) {
      max-height: 35vh;
    }
  }

  .modal-header {
    border-bottom: 0;
    flex-direction: column;

    .search-wrapper {
      margin-top: 25px;
      width: 100%;

      &.focused {
        border-color: $primary;
      }
    }
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .custom-checkbox {
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    padding: 15px 3px;

    &:last-child {
      border-bottom: none;
    }

    .custom-control-label {
      font-size: 0.94rem;
      font-weight: 500;
      padding-left: 32px;

      &:after {
        border-radius: 0;
        left: 0;
      }

      &:before {
        border-radius: 0;
        left: 0;
      }

      .number {
        color: #b4b4b4;
        font-weight: 400;
        margin-left: 10px;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background: $primary;
    }
  }

  .amc {
    padding: 1rem;

    .custom-switch {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      padding-left: 3rem;

      .info-icon {
        color: #122b28;
        font-size: 16px;
        margin-top: 3px;
      }
    }

    .custom-switch .custom-control-label::before {
      background: rgba(180, 180, 180, 0.4);
      border: 0;
      height: 16px;
      left: -3rem;
      width: 30px;
    }

    .custom-switch .custom-control-label::after {
      background: #fff;
      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.16));
      left: calc(-3rem + 2px);
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background: rgba($primary, 0.4);
    }

    .custom-switch
      .custom-control-input:checked
      ~ .custom-control-label::after {
      background: $primary;
    }

    .custom-control-label {
      color: #122b28;
      font-size: 1rem;
    }

    .invalid-feedback {
      color: $secondary;
      font-size: 12px;
      text-align: right;

      &.active {
        display: block;
      }
    }
  }

  .modal-footer {
    border-top: 0;

    .btn {
      min-width: 90px;
    }
  }
}

.confirm-amc-modal {
  .group-wrapper {
    margin-bottom: 15px;
    max-height: 200px;
    max-width: 350px;
    overflow: auto;
  }

  .message-single {
    align-items: center;
    display: flex;
    padding: 10px 10px 10px 0;

    .avatar {
      margin-right: 13px;
    }

    .name {
      color: #122b28;
      font-size: 0.94rem;
      margin: 0;
    }

    .number {
      color: #b4b4b4;
      display: inline-block;
      font-size: 0.94rem;
      margin-left: 10px;
    }
  }
}
