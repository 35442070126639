footer.chat-box {
  min-height: 56px;
  height: auto;
  border-top: 1px solid #c9c9c9;
  padding: 5px 10px;

  &.typing {
    .top-info {
      display: flex;
    }

    .chat-typing-action {
      display: flex;
    }

    .typing-box {
      .date-picker-label {
        display: block;
      }
    }
  }

  &.disabled {
    .top-info {
      display: none;
    }
  }

  .credits {
    padding: 4px 7px;
    border-radius: 13px;

    &.non-zero {
      background: #ffff84;
    }

    &.unicode {
      background: #fc7e00;
      color: #fff !important;
    }

    &.changed {
      animation: credits-highlight 500ms ease-out;
    }

    &.changed.unicode {
      animation: credits-highlight-orange 500ms ease-out;
    }
  }

  .message-scheduling {
    background: #fff;
    border-top: 1px solid #fc7e00;
    border-bottom: 1px solid #c9c9c9;
    margin: -6px -10px 10px;
    padding: 8px 10px;
    min-height: 40px;

    .title {
      display: flex;

      h3 {
        color: #707070;
        flex-grow: 1;
        font-size: 0.88rem;
        font-weight: bold;
        margin: 0;
      }

      .edit-link {
        color: $primary;
        cursor: pointer;
      }

      .close-icon {
        vertical-align: middle;
        color: #122b28;
        font-size: 1.19rem;
      }

      .info {
        color: #707070;
        font-size: 0.88rem;

        > span,
        > div {
          display: inline-block;
        }
      }
    }

    p {
      margin: 0;
      color: #707070;
    }
  }

  .image-uploads {
    display: flex;
    border-bottom: 1px solid #c9c9c9;
    background: #fff;
    padding: 10px 20px;
    margin: -5px -10px 10px;

    .thumbnail {
      height: 80px;
      width: 120px;
      overflow: hidden;
      margin-right: 8px;
      background-size: cover;
      border-radius: 10px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &.vertical {
        width: 58px;
      }

      &.button {
        border: 1px solid #122b28;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        i {
          color: #122b28;
          font-size: 22px;
        }

        span {
          color: #122b28;
          font-size: 12px;
          margin-top: 5px;
        }
      }

      .close-icon {
        align-items: center;
        background: #122b28;
        border-radius: 9px;
        color: #fff;
        display: flex;
        font-size: 12px;
        height: 18px;
        justify-content: center;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 18px;
        z-index: 999;
      }

      .modal-trigger {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
      }
    }
  }

  .uploaded-files {
    display: flex;
    border-bottom: 1px solid #c9c9c9;
    background: #fff;
    padding: 10px 20px;
    margin: -5px -10px 10px;

    .single {
      height: 80px;
      width: 120px;
      overflow: hidden;
      margin-right: 8px;
      background-size: cover;
      border-radius: 10px;
      position: relative;

      &:last-child {
        margin: 0;
      }

      &.vertical {
        width: 58px;
      }

      &.button {
        border: 1px solid #122b28;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .modal-trigger {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
      }

      .close-icon {
        background: #122b28;
        display: flex;
        width: 18px;
        height: 18px;
        justify-content: center;
        align-items: center;
        border-radius: 9px;
        color: #fff;
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 12px;
        z-index: 999;
      }
    }
  }

  .typing-box {
    display: flex;
    margin-top: 5px;
    padding-right: 1px;
    position: relative;

    &.has-value {
      .send-button {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .custom-file-label {
      background: transparent;
      border: 0;
      color: #122b28;
      font-size: 14px;
      justify-content: flex-start;
      padding-bottom: 0;
      padding-left: 0;

      &:focus {
        border: none;
        box-shadow: none;
      }
    }

    .custom-file-label:after {
      content: "";
      background: transparent;
    }

    .custom-file {
      width: 20px;
    }

    .attach_file {
      position: absolute;
      top: 0;
      left: 5px;

      i {
        vertical-align: middle;
        color: #555;
      }

      .custom-file-input {
        width: 20px;
      }
    }

    .typing-box-frame {
      position: relative;
      width: 100%;
      margin-right: 10px;
    }

    .message-type-input {
      border: 1px solid #e4e4e4;
      background: #fff;
      margin: 0 10px 0 0;
      padding: 5px 5px 5px 30px;
      height: 36px;
      min-height: 36px;
      max-height: 160px !important;
      border-radius: 18px;
      resize: none;
      outline: none;
      width: 100%;

      &:focus {
        border: 1px solid $primary;
        box-shadow: none !important;
      }

      &.with-signature {
        min-height: 58px;
        padding-bottom: 30px;
      }
    }

    .signature {
      z-index: 20;
      display: flex;
      align-items: center;
      padding: 0 0 0 14px;
      margin: 0;
      position: absolute;
      bottom: 0px;
      left: 11px;
      width: calc(100% - 25px);
      background: #fff;

      .info-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a {
        white-space: nowrap;
        padding: 0;
        z-index: 10;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .date-picker-label {
      width: 36px;
      min-width: 36px;
      height: 36px;
      border-radius: 50%;
      background: none;
      padding: 0;
      margin-right: 5px;
      align-items: center;
      justify-content: center;
      line-height: 0;
      position: relative;
      display: none;
      border: 1px solid $primary;

      .date-picker {
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary;
        font-size: 24px;
      }

      input {
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 36px;
        opacity: 0;

        &:focus {
          opacity: 0;
        }
      }
    }

    .send-button,
    .cancel-button {
      width: auto;
      height: 36px;
      border-radius: 18px;
    }

    .send-button {
      min-width: 36px;
      background: $primary;
      color: #fff;
      padding: 0;
      opacity: 0.5;
      pointer-events: none;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 1;
      font-size: 14px;

      &.scheduling {
        width: 72px;
        min-width: 60px;
        opacity: 1;

        i:first-child {
          margin-right: 5px;
        }

        .send-icon {
          display: inline-block;
        }

        .schedule-label {
          display: none;
        }

        @media (min-width: 1201px) {
          min-width: 110px;

          .send-icon {
            display: none;
          }

          .schedule-label {
            display: inline-block;
          }
        }

        &.editing {
          min-width: 80px;
          padding-right: 8px;
        }
      }

      i {
        line-height: 0;
      }
    }

    ::placeholder {
      color: #707070;
      opacity: 0.75;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .chat-typing-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 10px;

    .dropdown-content.info-dropup {
      li {
        min-height: 24px;

        span {
          padding: 6px 16px;
        }
      }
    }

    .file-input {
      align-items: center;
      display: flex;
      width: 36px;

      input {
        opacity: 0;
        width: 0;
      }
    }

    .left {
      display: flex;
      align-items: center;

      .btn {
        padding: 0 8px;

        &:active {
          background: none;
          color: $secondary;
        }

        &:focus {
          background: none;
          color: $secondary;
        }

        &:visited {
          background: none;
        }

        &:hover {
          background: none;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      line-height: 1;

      .select-wrapper {
        width: 58px;

        svg {
          fill: #122b28;
        }
      }

      .modal-trigger {
        font-size: 12px;
        color: #707070;
        display: flex;
        align-items: center;

        .number {
          color: #122b28;
        }

        i {
          margin-left: 5px;
          font-size: 12px;
        }
      }

      .info-icon {
        color: #122b28;
        margin-left: 5px;
        margin-right: 15px;
        font-size: 16px;
      }

      span {
        font-size: 12px;
        color: #707070;
      }
    }

    .right-options {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #707070;
      line-height: 1;

      input[type="checkbox"] {
        position: relative;
        top: -1px;
      }
    }

    .modal-trigger .template-icon {
      rect {
        fill: #122b28;
      }

      &.active rect,
      &:active rect,
      &:focus rect {
        fill: $secondary !important;
      }
    }
  }

  .merge-fields {
    display: none;
    border-top: 1px solid #c9c9c9;
    margin-top: 15px;
    margin-left: -10px;
    margin-right: -10px;
    padding: 5px 15px;

    &.active {
      display: block;
    }

    .title {
      font-size: 12px;
      margin-bottom: 0;
      color: #707070;
      font-weight: 500;
    }

    .btn-flat {
      padding-left: 0;
      padding-right: 0;
      margin-right: 10px;
      color: $primary;
      text-transform: none;
      font-size: 0.94rem;
      font-weight: 500;
      height: 30px;
    }
  }

  .alert.top-alert {
    z-index: unset;
    min-height: 40px;
    height: auto;
  }
}

@keyframes credits-highlight {
  0% {
    background-color: #ffff84;
  }
  10% {
    background-color: orange;
  }
  100% {
    background-color: #ffff84;
  }
}

@keyframes credits-highlight-orange {
  0% {
    background-color: #fc7e00;
  }
  10% {
    background-color: #f50000;
  }
  100% {
    background-color: #fc7e00;
  }
}

.message-textarea {
  overflow: hidden auto;

  .disabled-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 17px;
    background: #d9d9d9;
    z-index: 30;
    opacity: 0.7;
  }

  &.focus {
    border-color: $primary !important;
  }
}

.highlights {
  position: absolute;
  z-index: 3;
  pointer-events: none;
  overflow: hidden visible;
  width: 100%;
  height: auto;
  padding: 0;
  background: transparent;

  > div {
    width: 100%;
    height: 100% !important;
    overflow: auto !important;
    border-color: transparent !important;
    color: transparent !important;
  }

  mark {
    z-index: 3;
    pointer-events: all;
    padding: 0;
    border-radius: 2px;
    background: rgba(252, 126, 0, 0.8); // from #fc7e00
  }
}

.highlights-text {
  mark {
    padding: 0;
    border-radius: 2px;
    background: rgba(252, 126, 0, 0.8); // from #fc7e00
  }
}

footer.chat-box .typing-box .message-textarea .message-type-input {
  position: absolute;
  z-index: 2;
  max-height: unset !important;
  min-height: 100%;
  padding: 9px 5px 5px 24px;
  margin-right: 0;
  background: none;
  line-height: 1.2;
}
