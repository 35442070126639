.top-bars {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 9999;
}

.alert.top-alert {
  align-items: center;
  background: rgba(120, 192, 182, 0.4);
  border: 0;
  border-radius: 0;
  color: #122b28;
  display: flex;
  font-size: 0.88rem;
  height: 40px;
  justify-content: center;
  // left: 0;
  margin: 0;
  padding: 0 20px;
  // position: fixed;
  // right: 0;
  text-align: center;
  // top: 0;
  z-index: 9999;

  &.info {
    background: #bfe9ff;
  }

  &.warning {
    background: #ffe577;
  }

  &.danger {
    background: $secondary;
    color: #fff;

    svg {
      path {
        fill: #fff;
      }

      rect {
        fill: #fff;
      }
    }
  }

  &.inactive {
    background: #000;
    color: #fff;

    svg {
      path {
        fill: #fff;
      }

      rect {
        fill: #fff;
      }
    }
  }

  p {
    margin-bottom: 0;

    a {
      text-decoration: underline;
    }
  }

  .close {
    height: 40px;
    opacity: 1;
    padding: 0;
    width: 40px;

    i {
      color: #122b28;
      font-size: 16px;
    }
  }
}

.top-alert + .header {
  top: 40px;
}

.top-alert + .header + .sidebar {
  top: 100px;
}

.top-alert + .header + .sidebar + .page-wrapper {
  padding-top: 100px;
}

.top-alert + .top-alert {
  border-top: 1px solid #73baef;
}

// chat box alert

.chat-box {
  .top-alert {
    margin: -6px -10px 10px;
    padding: 5px 10px;
    position: relative;
  }
}
