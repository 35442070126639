.user-detail-tab {
  border-left: 1px solid #e1e1e1;
  padding-bottom: 300px;

  .sticky-content {
    border-bottom: 1px solid #e1e1e1;

    .user-action {
      margin-bottom: 0;
    }
  }

  .contacts-detail {
    padding-top: 23px;

    &.group {
      background-color: #f8f8f8;
    }

    &.group .info-single {
      margin-bottom: 25px;
    }

    .title {
      color: #707070;
      font-size: 13px;
      text-align: center;
      font-weight: 400;
    }

    .no-margin {
      margin-top: -20px;
      display: flex;
      align-items: center;
    }

    .message-single {
      &:hover {
        background-color: transparent;
      }

      &.contact-tab-single {
        margin-bottom: 0;
      }

      .no-margin {
        margin-top: -40px;
      }
    }

    label {
      margin-bottom: 0 !important;
    }

    .contact-tab-single {
      align-items: center;
      display: flex;
      margin-bottom: 20px;
      width: calc(100% - 43px);

      .avatar {
        height: 36px;
        width: 36px;
        min-width: 36px;
        font-size: 1.06rem;
      }

      .group-icon {
        opacity: 1;

        svg {
          min-width: 16px;
        }
      }

      &.branch {
        margin-bottom: 10px;

        &:before {
          content: "";
          width: 10px;
          height: 1px;
          background-color: #dbdbdb;
          margin-right: 4px;
          border-left: 1px solid #dbdbdb;
        }

        .group-icon {
          opacity: 0.25;
        }
      }

      .detail {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        overflow: inherit;
        width: calc(100% - 46px);
      }

      .detail-single {
        display: flex;
        width: 100%;
      }

      .info {
        display: flex;

        &.contact .group-icon {
          opacity: 0.25;
        }
      }

      .name {
        color: #122b28;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 400;
        position: relative;
        z-index: 9;
        text-overflow: ellipsis;

        .duplicate {
          opacity: 0.5;
        }
      }

      .hub-icon svg {
        min-width: 13px;

        path:not([fill="none"]) {
          fill: #b4b4b4;
        }
      }
    }
  }

  &.scrolled {
    .sticky-content {
      background: #f8f8f8;
      position: sticky;
      top: 0;
      z-index: 99;
    }

    .chat-avatar-wrapper {
      flex-direction: row;
      justify-content: flex-start;
      padding: 10px 15px;

      .big-avatar {
        font-size: 18px;
        height: 30px;
        line-height: 30px;
        margin-bottom: 0;
        min-width: 30px;
        width: 30px;
      }

      h5 {
        font-size: 1rem;
        white-space: nowrap;
      }
    }
  }

  .status-field {
    padding: 20px 20px 0;
    border-bottom: 1px solid #e1e1e1;

    .icon-label {
      width: 100%;
      display: flex;

      .arrow-icon {
        margin-left: auto;
        font-size: 13px;
        color: #707070;
        transition: all 0.3s ease-in-out;
      }
    }

    .btn-recheck {
      float: right;
      margin-right: 0;
      padding-right: 0;
    }

    &.with-collapse {
      .icon-label {
        &[aria-expanded="false"] {
          .arrow-icon {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  .top-action {
    position: absolute;
    top: 5px;
    right: 0;

    .btn-secondary {
      color: #122b28;

      &.dropdown-toggle {
        background: none;
      }

      &:after {
        display: none;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .icon {
      display: inline-block;
      width: 30px;
      text-align: left;

      img {
        width: 17px;
      }
    }

    .dropdown-item {
      color: #122b28;
      font-size: 0.875rem;
      font-weight: 500;

      &:focus {
        background-color: none;
      }

      &:hover {
        background-color: #fff;
      }
    }

    .dropdown-menu {
      border: 1px solid #707070;
      border-radius: 3px;
      @media (max-width: 600px) {
        left: -138px;
      }
    }
  }

  .chat-avatar-wrapper {
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    .export-icon {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
    }

    .details {
      align-items: center;
      display: flex;
      flex-direction: column;

      .info-icons {
        display: inline-flex;
        padding: 0;

        svg {
          width: 14px;
          opacity: 1;

          path {
            fill: #b4b4b4;
          }

          rect {
            fill: #b4b4b4;
          }
        }
      }

      .members-num {
        color: #b4b4b4;
      }

      .contact-num {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
      }

      .phone-icon {
        display: inline-flex;
        margin-left: 8px;

        svg {
          opacity: 0.25;
          min-width: 6px;
          max-height: 12px;
        }
      }

      .unsubscribe {
        display: inline-flex;
        margin-left: 8px;

        svg {
          max-width: 11px;
        }
      }
    }

    .big-avatar {
      font-size: 2.9375rem;
      font-weight: 400;
      height: 101px;
      line-height: 101px;
      margin-bottom: 22px;
      text-align: center;
      width: 101px;

      &:hover {
        color: #fff;
      }

      svg {
        height: 100%;
        width: 100%;
      }
    }

    h5 {
      color: #122b28;
      font-size: 1.25rem;
      font-weight: 400;
      margin: 0 0 5px;
    }
  }

  .same-number-label {
    font-size: 13px;
    text-align: center;
  }

  .info-single {
    #side_form_group_status .group-status {
      position: relative;

      .btn-recheck {
        position: absolute;
        right: 0;
        @media (min-width: 530px) and (max-width: 650px) {
          right: -30px;
        }
      }
      @media (min-width: 530px) and (max-width: 650px) {
        width: 95%;
      }
    }
  }

  .group-status {
    > span,
    a {
      align-items: center;
      display: inline-flex;
      margin-bottom: 20px;
      margin-right: 10px;
      min-width: 40px;

      span {
        color: #122b28;
        font-size: 0.94rem;
        margin-left: 5px;
      }
    }

    .light-icons {
      img {
        max-height: 15px;
        opacity: 0.25;
        width: 14px;
      }
    }

    .btn-recheck {
      margin-right: 0;
      float: right;
    }
  }

  .autoresponse {
    background: transparent;
    resize: none;
  }

  .group-message {
    background: transparent;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding-left: 0;
    padding-right: 0;
    resize: none;
  }

  .autoresponse-action {
    margin-top: 5px;
    text-align: right;

    a {
      color: $primary;
      margin-left: 10px;
    }
  }

  .info-icons {
    padding: 10px 15px;

    a {
      margin: 0 5px;
    }

    .dropdown-toggle {
      color: #122b28;
    }
  }

  .user-action {
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 10px 15px;
    text-align: center;
    margin-bottom: 30px;

    &.search-filter {
      background: transparent;
      height: auto;
      line-height: normal;
      position: relative;
    }

    .user-action-single {
      .unfavorite {
        display: none;
      }

      .hide-detail {
        display: none;
      }

      .label {
        padding: 5px;
        min-height: 70px;
        white-space: pre-wrap;
        margin-left: 5px;
      }

      .custom-label {
        width: 95px;
        min-height: 50px;
      }

      &.active {
        .favorite {
          display: none;
        }

        .show-detail {
          display: none;
        }

        .unfavorite {
          display: inline-block;
        }

        .hide-detail {
          display: inline-block;
        }
      }

      .icon {
        margin-top: 0;

        &.favorite {
          svg {
            width: 18px;

            path {
              fill: none;
              stroke: #122b28;
            }
          }
        }

        &.unfavorite {
          svg {
            width: 18px;
          }
        }

        svg {
          width: 18px;

          path {
            fill: #122b28;
          }

          rect {
            fill: #122b28;
          }
        }
      }
    }

    .search-wrapper-outer {
      a {
        min-width: 0;
      }
    }

    a {
      color: #122b28;
      display: inline-flex;
      flex-direction: column;
      min-width: 70px;
      padding: 0;

      &:focus {
        box-shadow: none;
      }

      .icon-wrapper {
        flex-grow: 1;
      }

      .label {
        font-size: 12px;
        margin-top: 8px;
        white-space: nowrap;
      }
    }
  }

  .info-wrapper {
    padding: 0 20px;

    .info-single {
      align-items: flex-end;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      padding-bottom: 5px;

      .edit {
        color: $primary;
        border-radius: 5px;
        font-size: 0.75rem;
        font-weight: 500;
        margin-left: 10px;
      }

      .info-left {
        label {
          margin: 0;
          font-size: 0.75rem;
          font-weight: 400;
          color: #707070;
          margin-bottom: 4px;
        }

        p {
          color: #122b28;
          font-size: 1rem;
          font-weight: 400;
          margin: 0;
          line-height: 20px;
        }

        .custom-select {
          border: none;
          width: 50%;
          background: url("../../img/icons/select.svg") right 0.75rem;
          background-repeat: no-repeat;
          background-size: 8px 10px;
          padding: 0 10px;
          height: 20px;
          background-position: 100% 6px;
          padding-left: 0;

          &:focus {
            box-shadow: none;
          }
        }
      }

      &.with-collapse {
        border-bottom: 0;
        display: block;
        padding-bottom: 0;

        label {
          align-items: center;
          color: #707070;
          display: flex;
          font-size: 0.75rem;
          font-weight: 400;
          justify-content: space-between;
          margin: 0 0 4px;

          &[aria-expanded="true"] {
            .arrow-icon {
              transform: rotate(-180deg);
            }
          }

          .arrow-icon {
            font-size: 13px;
            transition: all 0.3s ease-in-out;
          }
        }

        .text-grey {
          color: #8f8f8f;
        }

        p {
          margin: 0;
        }

        .info-left p {
          font-size: 0.9rem;
        }

        .contacts-collapse {
          padding-bottom: 0;
          position: relative;

          &:before {
            content: "";
            background-color: #dbdbdb;
            width: 1px;
            position: absolute;
            top: -4px;
            bottom: 20px;
          }

          &.show {
            border-bottom: 0;
          }
        }

        .select2-container {
          width: 65px !important;
        }

        .collapse {
          width: 100%;
          padding-bottom: 5px;

          &.show {
            border-bottom: 1px solid #e1e1e1;

            .select2-container {
              width: 65px !important;
            }
          }

          &.collapsing {
            overflow: hidden;
            white-space: nowrap;

            .select2-container {
              width: 65px !important;
            }
          }

          .contacts-collapse {
            border-left: 1px solid #dbdbdb;
            padding-bottom: 0;

            &.show {
              border-bottom: 0;
            }
          }
        }

        &.upload-image {
          .show {
            border-bottom: 1px solid #e1e1e1 !important;
            padding-bottom: 0;
          }

          .custom-file-label {
            background: transparent;
            border: none;
            padding-left: 0;
            padding-bottom: 0;
            color: #122b28;
            font-size: 14px;
            justify-content: flex-start;

            &:focus {
              border: none;
              box-shadow: none;
            }

            .image-name {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          .custom-file-label:after {
            content: "";
            background: transparent;
          }
        }

        .message-single {
          padding: 0;
          margin-left: -7px;
        }
      }
    }
  }
}
