/*------------------------------------------------------------------
[7. Sidebar]
*/

.sidebar {
  top: 60px;
  width: 60px;
  z-index: 99009;
  background-color: #eeeeee;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  left: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
  @media (max-width: 991px) {
    top: 0;
  }

  .slimScrollBar {
    opacity: 0 !important;
  }

  .slimScrollDiv {
    &:hover {
      .slimScrollBar {
        opacity: 0.4 !important;
      }
    }
  }

  a {
    &.active {
      svg {
        path {
          fill: #fff;
        }

        circle {
          fill: #fff;
        }
      }
    }

    &.hovered {
      svg {
        path {
          fill: #fff;
        }

        circle {
          fill: #fff;
        }
      }
    }

    &.hover {
      svg {
        path {
          fill: #fff;
        }
      }
    }

    svg {
      path {
        fill: #122b28;
      }
    }
  }
}

.sidebar-inner {
  height: 100%;

  .nav-tabs {
    border: 0;
  }

  .mobile-top {
    background: $primary;
    padding-bottom: 20px;

    li {
      a {
        align-items: center;
        color: #fff;
        display: flex;

        &.double-credit {
          svg {
            path {
              fill: #c8ff00;
            }
          }
        }
      }

      .nav-link {
        &.hovered .toggle-icon {
          .outlined {
            display: none;
          }

          .filled {
            display: inline-block;
          }
        }

        span {
          margin-left: 0;
        }

        .toggle-icon {
          margin-left: 0;
          padding: 0;
          position: relative;

          .filled {
            display: none;
          }

          svg path {
            fill: #fff;
          }
        }
      }
    }

    .badge-wrapper {
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      margin-right: 20px;

      .badge.badge-pill {
        height: 18px;
        line-height: 18px;
        padding: 0;
        position: absolute;
        right: -10px;
        top: 10px;
        width: 18px;
      }
    }

    .primary-badge {
      background-color: #9253ff;
    }
  }
}

.sidebar-menu ul {
  background-color: transparent;
  font-size: 0.875rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  margin-top: 0;

  li {
    width: 100%;
  }
}

.sidebar-menu li a {
  color: #122b28;
  display: block;
  font-size: 0.875rem;
  line-height: 46px;
  height: 46px;
  font-weight: 500;
  padding-left: 20px;
  overflow: hidden;
  position: relative;
  transition: all 0.4s ease;
}

.sidebar-menu li a span {
  margin-left: 30px;
  opacity: 0;
}
@media (max-width: 1366px) {
  .sidebar-menu li a span {
    margin-left: 40px;
  }
}
@media (max-width: 992px) {
  .sidebar-menu li a span {
    opacity: 1;
    /* margin-left: 10px; */
  }
}

.sidebar-menu li a img {
  margin-right: 0;
  font-size: 0.875rem;
  max-width: 20px;
  max-height: 20px;
  transition: all 0.4s ease;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.sidebar-menu li a img.white-icon {
  opacity: 0;
}

.sidebar-menu li a.active,
.sidebar-menu li a:focus,
.sidebar-menu li a:hover {
  background: $primary-light !important;
  color: #fff !important;
}

.sidebar-menu li a.active img.white-icon,
.sidebar-menu li a:hover img.white-icon {
  opacity: 1;
}

.sidebar-menu li a.active img.dark-icon,
.sidebar-menu li a:hover img.dark-icon {
  opacity: 0;
}

.sidebar-menu li a > .badge {
  margin-top: 10px;
  color: #fff;
  font-weight: 700;
}

.sidebar-menu li a i {
  font-size: 1rem;
  margin-left: 10px;
  margin-right: 10px;
}

.sidebar-menu li a i.back-icon {
  margin-left: 0;
}

.sidebar-menu li.active a {
  color: #00bf6f;
  background-color: #f3f3f3;
}

.menu-title {
  color: #000;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 40px;
  min-height: 40px;
  padding: 0 20px;
  text-transform: uppercase;
}

.menu-title > i {
  float: right;
  line-height: 40px;
}

.sidebar-menu li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  float: right;
  padding: 0;
}

.sidebar-menu li.menu-title a.add-user-icon {
  background-color: #eee;
  border-radius: 24px;
  color: #bbb;
  line-height: 24px;
  min-height: 24px;
  width: 24px;
  text-align: center;
  margin-top: 6px;
}

.sidebar-menu li.menu-title a.add-user-icon:hover {
  background-color: #00bf6f;
  color: #fff;
}

.sidebar-menu li.menu-title a.add-user-icon i {
  font-size: 0.875rem;
  margin-left: 0;
  margin-right: 0;
}

.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 0.9375rem;
  line-height: inherit;
  margin-bottom: 15px;
}

.sidebar-menu li.menu-title a.btn:hover {
  color: #fff;
}

.sidebar-menu ul ul a.active {
  color: #00bf6f;
  text-decoration: underline;
}

.sidebar-menu .credits {
  display: block;
  padding: 0 0px 10px 20px;
  font-size: 0.75rem;
}

.mobile-user-menu {
  color: #fff;
  display: none;
  font-size: 1.5rem;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 60px;
  z-index: 10;
}

.mobile-user-menu > a {
  color: #fff;
  padding: 0;
  line-height: 70px;
}

.mobile-user-menu > a i {
  font-size: 29px;
}

.mobile-user-menu > .dropdown-menu > a {
  line-height: 23px;
}

.profile-rightbar {
  display: none !important;
  color: #00bf6f;
  font-size: 1.625rem;
  margin-left: 15px;
}

.mobile_btn {
  display: none;
}

#sidebar-menu ul ul a {
  display: block;
  padding: 0 20px 0 60px;
}

#sidebar-menu ul ul ul a {
  padding-left: 80px;
}

#sidebar-menu ul ul ul ul a {
  padding-left: 100px;
}

#sidebar-menu ul ul {
  background-color: #f3f3f3;
}

.sidebar-menu > ul > li {
  position: relative;
}

.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 20px;
  display: inline-block;
  font-family: "FontAwesome";
  text-rendering: auto;
  line-height: 40px;
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.sidebar-menu .menu-arrow:before {
  content: "\f105";
}

.sidebar-menu li a.subdrop .menu-arrow {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.noti-dot:before {
  content: "";
  width: 5px;
  height: 5px;
  border: 5px solid #00bf6f;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #00bf6f;
  z-index: 10;
  position: absolute;
  right: 37px;
  top: 17px;
}

.noti-dot:after {
  content: "";
  border: 4px solid #00bf6f;
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 24px;
  width: 24px;
  -webkit-animation: pulse 3s ease-out;
  -moz-animation: pulse 3s ease-out;
  animation: pulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 1;
  opacity: 0;
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0);
  display: none;
  left: 0;
  position: fixed;
  height: 100%;
  top: 0;
  overflow: hidden;
  width: 100%;
  z-index: 99008;
}

.sidebar-overlay.opened {
  display: block;
}
