.new-contact-modal {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    min-height: 450px;
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }
  .input-field {
    margin-bottom: 28px;
    ::placeholder {
      color: #8f8f8f;
      font-size: 0.94rem;
    }
    &.warning {
      .form-control {
        border-color: $secondary;
      }
      .form-text {
        color: $secondary;
        display: block;
        text-align: right;
      }
    }
    .form-text {
      display: none;
    }
    .label {
      color: #707070;
      font-size: 0.75rem;
      margin-bottom: 0;
    }
  }

  .form-control {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    color: #122b28;
    padding: 0;
  }

  .old-number-selector {
    display: none;
    margin-bottom: 30px;
    text-align: center;
    &.active {
      display: block;
    }
    &.selected {
      .btn:not(.active) {
        border-color: #dbdbdb;
        path {
          fill: #dbdbdb;
        }
      }
    }
    .btn {
      align-items: center;
      border: 1px solid #707070;
      border-radius: 10px !important;
      display: inline-flex;
      height: 50px;
      justify-content: center;
      margin: 0 5px;
      padding: 0;
      transition: border-width 0.3s ease-in-out;
      width: 80px;
      &:hover {
        border: 4px solid #b69be4;
      }
      &.active {
        border: 4px solid $primary;
        box-shadow: none;
      }
    }
  }

  .modal-footer {
    border-top: 0;
    .btn {
      min-width: 90px;
    }
  }
}
