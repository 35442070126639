.short-links-modal,
.message-templates-modal,
.new-message-template-modal {
  .modal-dialog {
    max-height: 100%;
    @media (min-width: 576px) {
      max-width: none;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    @media (min-width: 1016px) {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .modal-content {
    min-height: 450px;
  }

  .message-template-modal-body {
    @media (max-width: $screen-md) {
      padding: 0px 20px;
    }
  }

  .message-template-modal-header {
    @media (max-width: $screen-md) {
      padding: 25px 20px;
    }
  }

  .message-modal-content {
    @media (max-width: $screen-md) {
      height: 100vh;
    }
  }

  table {
    position: relative;
  }

  thead {
    .header-row {
      th {
        position: sticky;
        top: 0;
        border-top: none;
        border-bottom: none;
        padding: 0;
        height: auto;

        span {
          background-color: #fff;
          font-size: 12px;
          color: #707070;
          font-weight: 400;
          padding: 12px;
          display: inline-block;
          border-top: 1px solid #d3d3d3;
          border-bottom: 1px solid #d3d3d3;
          width: 100%;
        }
      }
    }
    .message-modal-table-header {
      th {
        span {
          @media (max-width: $screen-md) {
            border-top: none;
          }
        }
      }
    }
  }

  td {
    color: #122b28;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .single-row {
    border-bottom: 1px solid #d3d3d3;

    &:hover {
      background-color: #fff;
    }

    td {
      color: #122b28;
      font-size: 15px;
      font-weight: 500;
      padding-top: 18px;
      padding-bottom: 18px;
      border-top: none;

      button {
        line-height: 1;
        padding: 1px 4px;
        border: 0;
        background: none;
      }
    }
  }

  .row-search {
    margin-bottom: 24px;
  }

  .btn-nevermind,
  .btn-delete {
    font-size: 12px;
    text-transform: none;
    color: #5517c1;
    padding: 0;

    &[disabled] {
      opacity: 0.7;
    }

    &:hover {
      background-color: transparent;
    }

    &:focus {
      outline: 0;
    }
  }

  .btn-delete {
    color: #e03737;
  }

  .btn-tutorial {
    margin-left: 40px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    text-transform: none;
    color: #5517c1;
    font-size: 12px;
    vertical-align: middle;

    &:hover {
      background-color: transparent;
    }

    span {
      margin-left: 8px;
      font-weight: 500;
    }
  }

  .merge-fields {
    h5 {
      font-size: 12px;
      font-weight: 500;
    }

    button {
      padding-left: 0;
      padding-right: 0;
      margin-right: 10px;
      color: $primary;
      text-transform: none;
      font-size: 0.94rem;
      font-weight: 500;
      height: 30px;
    }
  }
  .message-template-content {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .message-template-name {
    @media (max-width: $screen-md) {
      font-size: 16px;
    }
  }
}

.short-links-modal {
  tr td:first-child {
    max-width: 200px;
  }

  tr td:last-child {
    max-width: 150px;
  }

  tr td:nth-child(2) {
    max-width: 250px;
  }

  tr td:nth-child(3) {
    max-width: 250px;
  }
}

.message-templates-modal {
  tr td:first-child {
    width: 200px;
    max-width: 200px;
  }

  tr td:last-child {
    width: 120px;
  }

  tr td:nth-child(2) {
    max-width: 400px;
  }
}
