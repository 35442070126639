.voice-broadcast-modals {
  z-index: 999999 !important;

  .voice-broadcast.wizard {
    padding-right: 14px;
  }

  .label-warning {
    font-size: 11px;
    background: #fb7e00;
    text-align: center;
    color: white;
    padding: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .btn-delete {
    color: rgb(226, 78, 0);
    padding-left: 0;
  }

  .custom-input {
    opacity: 0;
    width: 1px;
  }

  a {
    text-decoration: none !important;
  }

  textarea {
    width: 100%;
    padding: 10px 15px;
    resize: none;
    font-size: 15px;
    color: #122b28;
    line-height: 1.35;

    &::placeholder {
      font-size: 15px;
      color: #8f8f8f;
    }
  }

  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .modal-content {
    min-height: 450px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    border: none;

    @media (max-width: 576px) {
      height: calc(90% - 50px);

      .modal-body {
        overflow: auto;

        .scroll-area,
        .scroll-area > div,
        .broadcast-library .broadcast-list {
          max-height: unset !important;
        }
      }
    }

    &.auto-height {
      min-height: auto;

      .scroll-area div:first-child {
        min-height: auto !important;
      }
    }

    .steps ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      max-width: 214px;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      li {
        position: relative;

        &:first-child:before {
          display: none;
        }

        &:before {
          content: "";
          position: absolute;
          height: 3px;
          background-color: $primary;
          left: -80px;
          right: 0;
          top: 50%;
          transform: translate(0, -1.5px);
        }

        &.done:before {
          content: "";
          position: absolute;
          height: 3px;
          background-color: $primary;
          left: -80px;
          right: 0;
          top: 50%;
          transform: translate(0, -1.5px);
        }

        &.disabled:before {
          content: "";
          position: absolute;
          height: 3px;
          background-color: #dbdbdb;
          left: -80px;
          right: 0;
          top: 50%;
          transform: translate(0, -1.5px);
        }

        a {
          height: 22px;
          width: 22px;
          background-color: #dbdbdb;
          border-radius: 50%;
          text-align: center;
          display: block;
          position: relative;
          z-index: 2;
        }

        &.current a {
          background-color: $primary;
        }

        &.done a {
          background-color: $primary;
        }

        .number {
          color: #fff;
        }
      }
    }

    .actions ul {
      display: flex;
      list-style: none;
      justify-content: space-between;
      padding: 0;

      li {
        .back-btn {
          background-color: #fff;
          color: $primary;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba($primary, 0.5);
          height: 36px;
          min-width: 90px;
          border-radius: 5px;
        }
        // a[href$="#next"]{
        //   pointer-events: none;
        // }
        .next-btn {
          border: 1px solid rgba($primary, 0.5);
          height: 36px;
          min-width: 90px;
          border-radius: 5px;
          background-color: rgba($primary, 0.5);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
          cursor: default;
        }

        &.disabled {
          visibility: hidden;
        }
      }

      a {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .modal-header {
    padding: 22px;
    border-bottom: none;
    position: relative;
    height: 1px;

    .close-icon {
      position: absolute;
      top: 15px;
      right: 10px;
      cursor: pointer;
    }
  }

  .modal-body {
    margin: 22px 0;
    padding: 0 8px 0 22px;
  }

  .modal-footer {
    padding: 0 22px 22px;
    border-top: none;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .section-title {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;

    i {
      font-size: 18px;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .broadcast-type {
    margin-top: 60px;
    @media (min-width: 576px) {
      margin-bottom: 60px;
    }

    .single {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $primary;
      height: 100px;
      width: 96px;
      border: 1px solid #707070;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 7px;
      text-align: center;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }

      &.active:not(.disabled),
      &:focus:not(.disabled) {
        box-shadow: none;
        border: 3px solid $primary;
      }

      i {
        font-size: 26px;
      }

      span {
        font-size: 12px;
        font-weight: 600;
        margin-top: 12px;
      }

      svg path {
        fill: $primary;
      }
    }
  }

  .voice-record {
    padding: 90px 0 140px;
  }

  .broadcast-setting {
    position: relative;
    padding-left: 6px;

    .audioplayer-bar {
      height: 4px;
    }

    .sending-as {
      margin-top: 40px;
      margin-bottom: 30px;

      .label {
        color: #707070;
        font-size: 0.75rem;
        margin-bottom: 0;
      }

      .input-group {
        display: flex;
        position: relative;
        width: 100%;
      }

      .form-control {
        border: none;
        width: 100%;
        padding-left: 0;
        border-bottom: 1px solid #dbdbdb;
      }

      .input-group-prepend {
        width: 100%;
        margin-top: 0;
      }

      &.collapse-detail {
        margin-top: 0;
        margin-bottom: 0;

        .input-group-prepend {
          margin-bottom: 0;
        }

        .form-control {
          height: 25px;
          padding-top: 0;
        }
      }
    }

    .input-group-prepend {
      width: 100%;
      position: relative;
      margin-top: 35px;
      margin-bottom: 20px;

      textarea {
        padding: 15px 15px 25px;
      }

      .action {
        position: absolute;
        right: 8px;
        bottom: 12px;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }

    .input-field {
      .label {
        color: #707070;
        font-size: 12px;
        margin-left: 5px;
        margin-bottom: 0;
      }

      .custom-select {
        color: #122b28;
        font-size: 1rem;
        background: none;
        border: none;
        border-bottom: 1px solid #dbdbdb;
        padding: 0;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .voice-answer {
      margin-top: 30px;

      a[aria-expanded="false"] {
        color: #707070;
        display: flex;
        align-items: center;
        font-size: 12px;

        i {
          margin-left: auto;
        }
      }

      a[aria-expanded="true"] {
        color: #707070;
        display: flex;
        align-items: center;
        font-size: 12px;

        i {
          margin-left: auto;
          transform: rotateX(180deg);
        }
      }
    }

    .cost {
      font-size: 16px;
    }
  }

  .message-scheduling {
    background: #fff;

    .title {
      display: flex;
      flex-direction: column-reverse;
      align-items: normal;

      h3 {
        color: #707070;
        flex-grow: 1;
        font-size: 0.88rem;
        font-weight: bold;
        overflow: hidden;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .edit-link {
        display: inline-block;
        vertical-align: middle;
        color: $primary;
        cursor: pointer;
      }

      .close-icon {
        vertical-align: middle;
        color: #122b28;
        font-size: 1.19rem;
      }
    }

    p {
      margin: 0;
      color: #707070;
    }

    .date-time-picker {
      position: relative;

      .picker-content-positioner {
        right: -16px;
      }
    }
  }

  .step1-validated {
    .single {
      color: #dbdbdb;
      border-color: #dbdbdb;

      svg path {
        fill: #dbdbdb;
      }

      &.active,
      &:focus {
        border: 3px solid $primary;
        box-shadow: none;
        color: $primary;

        i {
          color: $primary;
        }

        svg path {
          fill: $primary;
        }
      }
    }

    .actions li .next-btn {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }

  .label {
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
  }

  .broadcast-template-wrapper {
    .info-single {
      display: none;
    }

    .warning-text {
      display: none;
    }

    .custom-switch {
      border-bottom: none;
    }

    &.show-template {
      margin-bottom: 20px;

      .info-single {
        align-items: flex-end;
        border-bottom: 1px solid #e1e1e1;
        display: flex;
        padding-bottom: 5px;

        &.with-collapse {
          flex-direction: column;
          align-items: flex-start;

          label {
            display: block;
            margin-bottom: 2px;
          }

          input {
            font-size: 16px !important;
          }

          .edit {
            color: #5517c1;
            border-radius: 5px;
            font-size: 0.75rem;
            font-weight: 500;
            margin-left: 10px;
          }
        }

        .label {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          color: #707070;
          margin-bottom: 5px;
        }

        p {
          color: #122b28;
          font-size: 1rem;
          font-weight: 400;
          margin: 0;
          line-height: 20px;
        }

        a {
          color: #5517c1;
          border-radius: 5px;
          font-size: 0.75rem;
          font-weight: 500;
          margin-left: 10px;
        }

        .warning-text {
          display: none;
        }

        &.invalid {
          border-bottom: 1px solid $secondary;
          position: relative;

          .warning-text {
            font-size: 12px;
            color: $secondary;
            display: inline;
            position: absolute;
            bottom: -20px;
            right: 0;
          }
        }
      }
    }
  }

  .action-buttons {
    a {
      background-color: $primary;
      padding: 8px 32px;
      border-radius: 5px;
      border: 1px solid $primary;
    }

    .btn {
      height: 36px;
      min-width: 90px;
      font-weight: 500;
      line-height: 1;

      &:active,
      &:focus,
      &:focus:active {
        box-shadow: none;
      }
    }

    .btn-schedule {
      padding: 0;
      width: 36px;
      min-width: unset;
      border-radius: 50%;

      svg {
        vertical-align: text-top;
      }
    }

    .btn-submit {
      min-width: 90px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mobile-record {
    padding-top: 40px;

    .short-info {
      font-size: 14px;
      color: #707070;
    }

    .search-wrapper-outer {
      display: flex;
    }

    .search-wrapper {
      background: #fff;
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      height: 34px;
      padding: 0 10px;
      border-radius: 17px;
      flex-grow: 1;
      margin: 25px 0;

      &.focused {
        border-color: $primary;
      }
    }

    .scrolling-content {
      margin-bottom: 50px;
      margin-top: 25px;

      .number {
        font-size: 14px;
        color: #122b28;
      }

      .name {
        font-size: 14px;
        color: #707070;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      a i {
        font-size: 16px;
      }

      p {
        margin: 0;
      }

      .single {
        padding: 10px 10px 10px 0;
        border-top: 1px solid #d3d3d3;
        border-bottom: 1px solid #d3d3d3;
        justify-content: space-between;
        align-items: center;

        & + .single {
          border-top: 0;
        }

        .content {
          overflow: hidden;
        }
      }
    }

    .connecting-view {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 60px 0;
      font-size: 14px;

      .title {
        color: #707070;
      }

      .spinner-border {
        margin-top: 16px;
        margin-bottom: 42px;
      }
    }

    .connected-view {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px 0;
      color: #707070;

      .time {
        font-size: 20px;
      }

      i {
        color: #b4b4b4;
        font-size: 60px;
        margin: 15px 0;
      }

      .label {
        font-size: 14px;
      }
    }
  }

  .upload-mp3 {
    padding-top: 40px;

    .dropzone {
      background-color: #fff;
      border: 1px dashed $primary;
      border-radius: 14px;
      width: 100%;
      min-height: 200px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 40px;
      padding: 20px;
      cursor: pointer;

      .dz-message {
        width: 100%;
        margin: 0;
        text-align: center;
      }

      span {
        svg {
          height: 55px;
          width: 55px;
          margin-bottom: 22px;

          path {
            fill: #b4b4b4;
          }
        }

        p {
          font-size: 14px;
          color: #707070;
        }
      }

      &.dz-drag-hover .dz-message {
        opacity: 0.5;
      }
    }

    .uploading {
      background-color: #fff;
      border: 1px dashed $primary;
      border-radius: 14px;
      width: 100%;
      min-height: 200px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 40px;
      justify-content: center;

      .spinner-border {
        height: 50px;
        width: 50px;
        border-width: 6px;
      }
    }

    .uploaded {
      margin-bottom: 100px;
      margin-top: 50px;

      .audioplayer-bar {
        height: 4px;
        top: 20px;
      }

      .info-single {
        align-items: flex-end;
        border-bottom: 1px solid #e1e1e1;
        display: flex;
        padding-bottom: 5px;
        margin-top: 50px;

        .info-left {
          max-width: 80%;

          p {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .label {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          color: #707070;
          margin-bottom: 5px;
        }

        p {
          color: #122b28;
          font-size: 1rem;
          font-weight: 400;
          margin: 0;
          line-height: 20px;
        }

        a {
          color: #5517c1;
          border-radius: 5px;
          font-size: 0.75rem;
          font-weight: 500;
          margin-left: 10px;
        }
      }
    }
  }

  .text-to-voice {
    .input-field {
      margin-top: 20px;
      margin-bottom: 30px;

      .label {
        color: #707070;
        font-size: 0.75rem;
        margin-bottom: 0;
      }

      .input-group {
        display: flex;
        position: relative;
        width: 100%;
      }

      .form-control {
        border: none;
        width: 100%;
        padding-left: 0;
        border-bottom: 1px solid #dbdbdb;
      }

      .input-group-prepend {
        width: 100%;
      }
    }

    .text-to-voice-textarea {
      .label {
        color: #707070;
        font-size: 0.75rem;
        margin-bottom: 5px;
      }

      .input-group-prepend {
        width: 100%;
        position: relative;
        padding-left: 6px;

        textarea {
          padding: 15px 15px 45px;

          ::placeholder {
            font-size: 15px;
            color: #8f8f8f;
          }
        }

        .action {
          position: absolute;
          right: 8px;
          bottom: 12px;

          svg {
            height: 16px;
            width: 16px;

            path {
              fill: #122b28;
            }
          }

          a {
            border: none;
            padding: 0;
          }

          .play {
            margin-right: 8px;
          }
        }
      }
    }

    .templates {
      border: 1px solid #dbdbdb;
      padding: 15px;
      max-height: 350px;
      overflow: auto;

      .search-wrapper-outer {
        display: flex;
      }

      .search-wrapper {
        background: #fff;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        height: 34px;
        padding: 0 10px;
        border-radius: 17px;
        flex-grow: 1;
        margin: 20px 0;

        &::placeholder {
          font-size: 18px;
          color: #8f8f8f;
        }

        &.focused {
          border-color: $primary;
        }
      }

      ul {
        list-style: none;
        padding-left: 0;

        li {
          border-bottom: 1px solid #dbdbdb;
          padding-bottom: 10px;
          margin-top: 20px;
          cursor: pointer;

          &:last-child {
            border-bottom: none;
          }
        }

        .meta {
          align-items: center;
          display: flex;
          width: 100%;
          white-space: nowrap;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #122b28;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          svg {
            height: 14px;
            margin-left: 7px;
            margin-right: 12px;

            path {
              fill: #122b28;
            }
          }

          .date {
            font-size: 12px;
            color: #8f8f8f;
            margin-left: auto;
          }
        }

        .detail {
          font-size: 14px;
          color: #8f8f8f;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .broadcast-library {
    .search-wrapper-outer {
      display: flex;
    }

    .search-wrapper {
      background: #fff;
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      height: 34px;
      padding: 0 10px;
      border-radius: 17px;
      flex-grow: 1;
      margin: 20px 0;

      ::placeholder {
        font-size: 16px;
        color: #8f8f8f;
      }

      &.focused {
        border-color: $primary;
      }
    }

    .broadcast-list {
      overflow: auto;
      max-height: 350px;
      margin-bottom: 30px;
      overflow-x: hidden;
    }

    .meta {
      align-items: center;
      display: flex;
      width: 100%;
      white-space: nowrap;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #122b28;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      i {
        font-size: 18px;
        margin: 0 12px;
      }

      svg {
        height: 15px;
        margin: 0 12px;

        path {
          fill: #122b28;
        }
      }

      .date {
        font-size: 12px;
        color: #8f8f8f;
        margin-left: auto;
      }
    }

    .detail {
      font-size: 14px;
      color: #8f8f8f;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .custom-control {
      border-bottom: 1px solid #d3d3d3;
      padding-bottom: 16px;
      padding-top: 18px;

      &:last-child {
        border: none;
      }
    }

    .audioplayer-bar {
      height: 4px;
      top: 20px;
    }

    .custom-control-label:before {
      left: 0;
      top: 20px !important;
    }

    .custom-control-label:after {
      left: 0;
      top: 20px !important;
    }
  }
}
