.chat-main-wrapper.active {
  display: table;
}

.mobile-user-menu {
  .header-action-modal {
    .modal-dialog {
      width: 100%;
      margin: 0;
      align-items: flex-start;

      .modal-content {
        border: none;
        background: $primary;

        .close {
          text-align: center;
          color: #fff;
          opacity: 1;

          i {
            font-size: 45px;
          }
        }
      }

      .modal-body {
        .user-menu {
          display: flex;
          list-style: none;
          padding-left: 0;

          li {
            margin-right: 31px;

            .nav-link {
              color: #fff;
              padding: 0;
              position: relative;

              .badge {
                position: absolute;
                color: #fff;
                top: 4px;
                right: -10px;
                background: #122b28;
                width: 17px;
                height: 17px;
                line-height: 17px;
                font-size: 11px;
                font-weight: 500;
                border-radius: 50%;
                text-align: center;
                padding: 0;
              }
            }
          }
        }

        .left-menu {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {
            line-height: 1.5;
            padding: 10px 0;
          }

          .dropdown-toggle {
            padding: 0;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
          }

          .dropdown-menu {
            min-width: 136px;

            .dropdown-item {
              height: 30px;
              line-height: 30px;
            }

            .dropdown-divider {
              border: none;
            }
          }

          .free-trial {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
          }

          .credits {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.page-wrapper.inbox {
  .chat-wrap-inner {
    .no-message-info {
      color: #707070;
      font-size: 2.375rem;
      font-weight: 300;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      @media (max-width: 900px) {
        font-size: 1.5rem;
      }
      @media (max-width: 600px) {
        font-size: 1rem;
      }

      .no-message-icon {
        color: #dbdbdb;
        font-size: 5.94rem;
        @media (max-width: 900px) {
          font-size: 3.13rem;
        }
        @media (max-width: 600px) {
          font-size: 1.88rem;
        }
      }

      .call-broadcast-button {
        min-width: 285px;

        .button-small {
          padding: 5px 7px;
          min-width: 120px;

          &:hover {
            color: #fff;
          }

          &:first-child {
            margin-right: 10px;
          }

          &:last-child {
            margin-left: 10px;
          }

          img {
            margin-right: 7px;
          }
        }
        @media (max-width: 900px) {
          margin-top: 20px;
        }
      }
    }
  }

  .chat-sidebar {
    @media (max-width: 1200px) {
      width: 250px;
    }
  }

  .task-view {
    .fixed-header {
      padding: 16px 15px;

      .navbar {
        display: flex;
        justify-content: space-between;

        .search-wrapper {
          background: #fff;
          border: 1px solid #ddd;
          display: flex;
          align-items: center;
          height: 34px;
          padding: 0 10px;
          border-radius: 17px;

          &.focused {
            border: 1px solid $primary;

            .search-icon {
              display: none;
            }

            .dropdown {
              display: block;
            }
          }

          &.has-value {
            .clear-icon {
              display: block;
            }
          }

          .selected-value {
            color: $primary;
          }

          input {
            border: none;
            background: none;
            padding-left: 5px;
            width: auto;
            min-width: 1px;
            flex-grow: 1;
          }

          .search-action {
            display: flex;
            align-items: center;
          }

          .dropdown {
            display: none;

            .dropdown-toggle {
              border: none;
              background: none;
              margin-left: 0;

              &:focus {
                outline: 0;
              }
            }

            .dropdown-menu {
              right: 0;
              left: auto;
            }
          }

          .clear-icon {
            display: none;
            font-size: 20px;
            color: $primary;
            margin-left: 5px;
          }

          .search-icon {
            font-size: 0.8125rem;
          }
        }

        .cancel-btn {
          font-size: 2rem;
        }

        .action {
          display: flex;
          align-items: center;

          .call {
            color: #fff;
            display: flex;
          }

          .call-not {
            display: none !important;
          }

          &.active {
            .call {
              display: none;
            }

            .call-not {
              display: flex !important;

              svg {
                height: 20px;
                width: 20px;

                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }

      .nav-tabs {
        min-height: 56px;

        .nav-item {
          .nav-link {
            padding: 15px;
          }
        }
      }

      .new {
        .btn-secondary {
          color: #fff;
          background: #c0392b;
          font-size: 1rem;
          font-weight: 500;
          padding: 9px 29px;
          border-radius: 5px;

          &:after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #707070;
          border-radius: 3px;

          .dropdown-item {
            display: flex;
            align-items: center;
            color: #122b28;
            font-size: 0.875rem;
            font-weight: 500;

            &:focus {
              background-color: none;
            }

            &:hover {
              background-color: #fff;
            }

            .icon {
              display: inline-block;
              width: 40px;
              text-align: left;
            }
          }
        }
      }

      &.secondary {
        padding: 0 10px;
        height: 47px;
        display: flex;
        align-items: center;
        background: $primary-light;
        border-left: 1px solid lighten($primary, 20%);
        border-right: 1px solid lighten($primary, 20%);

        .avatar-wrapper {
          display: flex;
          align-items: center;
          width: calc(100% - 48px);
          max-width: calc(100% - 48px);

          .avatar {
            width: 30px;
            min-width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 0.88rem;
            font-weight: 400;
            color: #fff;
            margin-right: 10px;

            &.group-avatar {
              padding: 1px;
            }
          }

          .chat-info {
            display: flex;
            align-items: center;
            width: calc(100% - 72px);
            max-width: calc(100% - 72px);

            strong {
              display: inline-block;
              max-width: 100%;
              margin-right: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 1rem;
              color: #fff;
              font-weight: 500;
            }

            .icon {
              svg path {
                fill: #fff;
              }
            }
          }
        }

        .navbar {
          display: flex;
          justify-content: space-between;
          width: 100%;

          &.group {
            strong {
              margin-right: 11px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              max-width: 225px;
            }
          }

          .close {
            background: $primary;
            width: 34px;
            height: 34px;
            line-height: 34px;
            min-width: 34px;
            text-align: center;
            border-radius: 50%;
            color: #fff;
            opacity: 1;
            margin-right: 20px;
            margin-left: 5px;

            i {
              font-size: 1rem;
            }
          }
        }

        .dropdown {
          .filter-number {
            color: #707070;
            font-size: 0.8125rem;
            font-weight: 400;

            &:after {
              vertical-align: -1px;
              border-top: 0.4rem solid;
              border-bottom: 0;
              border-right: 0.4rem solid transparent;
              border-left: 0.4rem solid transparent;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

.nav-tabs {
  .nav-item {
    flex-grow: 1;
    text-align: center;

    .nav-link {
      color: #122b28;
      font-size: 0.875rem;
      font-weight: 400;

      &:hover {
        background: none;
      }
    }
  }
}

.chat-sidebar .chat-contents {
  position: relative;

  &.message {
    padding: 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  .date-picker-wrapper .input-group-addon {
    width: 25px;
    height: 25px;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .date-picker-wrapper input.form-control {
    height: 0 !important;
    width: 0;
    padding: 0;
    border: none;
    opacity: 0;
  }

  .date-picker-wrapper .input-group-addon img {
    width: 18px;
  }

  .table-content {
    .button-flat {
      background: $primary;
      width: 100%;
      display: block;
      text-align: center;
      height: 60px;

      i {
        color: #fff;
        font-size: 24px;
        line-height: 60px;
      }
    }

    .message-saved.modal {
      &.show {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

      .modal-body {
        background: $primary;
        padding: 35px;

        p {
          margin: 0;
          font-size: 20px;
          font-weight: bold;
          color: #fff;
          text-align: center;
        }
      }
    }

    .defult-message {
      color: #707070;
      font-size: 24px;
      font-weight: 300;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .contact-detail-slide {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;

      &.show {
        .modal-dialog {
          -webkit-transform: translate(0, 0) !important;
          transform: translate(0, 0) !important;
        }
      }

      .modal-dialog {
        height: 100%;
        min-height: 100%;
        margin: 0;
        -webkit-transform: translate(100%, 0) !important;
        transform: translate(100%, 0) !important;
      }

      .modal-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: none;
        background: #f8f8f8;
        overflow-y: auto;

        .modal-body {
          padding: 0;

          .top-action {
            top: 80px;
          }
        }
      }
    }
  }

  .search {
    position: relative;

    input {
      background: #f5f5f5;
      border: 1px solid #dbdbdb;
      border-radius: 28px;
      font-size: 1rem;
      font-weight: 400;
      height: 33px;
      margin-bottom: 12px;
      padding: 8px;
      width: 100%;
    }

    ::placeholder {
      color: #707070;
    }

    i {
      font-size: 0.8125rem;
      position: absolute;
      right: 31px;
      top: 29px;
    }
  }

  .group-name {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 9px;
    padding: 20px 20px 0;

    h3 {
      align-items: center;
      color: #122b28;
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0 0 0 15px;
    }

    .edit {
      border-radius: 5px;
      color: $primary;
      font-size: 0.75rem;
      font-weight: 400;
      margin-left: 13px;
      padding: 1px 7px;

      &:hover {
        border: 1px solid $primary;
      }
    }
  }

  .contact-single {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    &:hover {
      .group-action {
        display: none !important;
      }

      .action-hover {
        display: flex;
      }
    }

    .action-hover {
      display: none;
      width: 50px;
      justify-content: space-between;

      .close-button,
      .delete-icon {
        position: relative;
        right: auto;
      }

      .custom-checkbox {
        margin-right: 20px;
      }
    }

    &.has-collapse {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .arrow-d,
      .arrow-r {
        margin-left: 10px;
        color: #707070;
      }
    }

    .number-type {
      margin-right: 13px;
    }

    &.disabled {
      &:hover {
        .name strong {
          color: #122b28;
        }
      }

      .action-hover {
        width: 50px;
      }

      .avatar {
        background: #dbdbdb !important;

        &.img {
          background: #fff !important;
        }
      }

      .name strong {
        color: #c4c4c4;
      }
    }

    .custom-control {
      margin-left: auto;
      margin-right: 20px;
      margin-top: -10px;
    }

    .avatar {
      background: #fff;
      width: 42px;
      height: 42px;
      line-height: 42px;
      color: #fff;
      font-size: 1.25rem;
      font-weight: 400;

      &.purple {
        background: #db83e6;
      }

      &.teal {
        background: #20cb96;
      }

      &.blue {
        background: #73baef;
      }

      &.orange {
        background: #fa8d0e;
      }

      &.green {
        background: #abce68;
      }
    }

    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 2px;
      color: #122b28 !important;

      a {
        color: #122b28;
      }

      strong {
        color: #122b28;
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .close-button {
      border: 1px solid $primary !important;
      border-radius: 5px !important;
      color: $primary;
      width: 18px;
      height: 18px;
      transition: all 0.2s ease-in-out;
      position: absolute;
      right: 48px;
      text-align: center;

      i {
        font-size: 16px;
        line-height: 18px;
      }

      &:hover {
        color: #c0392b;
        border: 1px solid #c0392b !important;
      }
    }

    .delete-icon {
      color: $primary;
      position: absolute;
      right: 20px;
      transition: all 0.2s ease-in-out;

      i {
        font-size: 22px;
      }

      &:hover {
        color: #c0392b;
      }
    }
  }
  // collaspe start//
  .custom-collapse {
    .contact-single {
      &:hover {
        .close-button {
          visibility: visible;
        }

        .delete-icon {
          visibility: visible;
        }
      }

      .close-button {
        border: 1px solid $primary !important;
        border-radius: 5px !important;
        color: $primary;
        width: 22px;
        height: 22px;
        transition: all 0.2s ease-in-out;
        position: absolute;
        right: 48px;
        text-align: center;
        visibility: hidden;

        i {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .delete-icon {
        color: $primary;
        position: absolute;
        right: 20px;
        visibility: hidden;
        transition: all 0.2s ease-in-out;

        i {
          font-size: 22px;
        }
      }
    }
  }
}
// MODAL

.inbox-modal.view-long-message-modal {
  .modal-dialog {
    max-width: 762px;
    .modal-content {
      padding: 14px 15px 10px 15px;
      border: none;
      box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
      border-radius: 5px;

      .close {
        opacity: 1;
        outline: 0;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 100;
      }
    }
  }
}

.inbox-modal.confirm-group-message {
  .modal-dialog {
    max-width: 762px;

    .modal-content {
      padding: 14px 25px 27px 50px;
      border: none;
      box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
      border-radius: 5px;

      .modal-header {
        border-bottom: none;
        padding: 0;

        .close {
          padding: 0;
          color: #c0392b;
          opacity: 1;
          font-size: 2.1875rem;
          font-weight: bold;

          &:focus {
            outline: 0;
          }
        }
      }

      .modal-body {
        padding: 0;

        .title {
          display: flex;
          align-items: center;

          h4 {
            color: #122b28;
            font-size: 1.25rem;
            font-weight: bold;
            margin: 30px 0 25px;
          }

          a {
            font-size: 1.25rem;
            font-weight: 400;
            color: #c0392b;
            margin-left: 30px;

            strong {
              color: #122b28;
              font-weight: bold;
            }
          }
        }

        .content {
          &.caller-info {
            p {
              margin: 0 0 30px;
              color: #707070;
              font-size: 16px;
              font-weight: 400;
            }
          }

          .wrapper {
            margin-top: 20px;

            strong {
              color: #122b28;
              font-size: 1rem;
              font-weight: 500;
            }

            i {
              color: #122b28;
              font-size: 0.8125rem;
            }
          }

          span {
            color: #707070;
            font-size: 1rem;
            font-weight: 400;
            margin-right: 7px;
          }

          .info-single {
            &.message-report {
              margin-top: 15px;
              margin-right: 0;
            }
            margin-right: 25px;
            margin-top: 27px;
          }

          .custom-control-label::before {
            border: 1px solid #122b28;
          }

          .custom-checkbox
            .custom-control-input:checked
            ~ .custom-control-label::before {
            border: 1px solid #122b28;
          }

          .custom-checkbox
            .custom-control-input:checked
            ~ .custom-control-label::after {
            background-image: url("../img/icons/black-checked-icon.svg");
          }

          .label {
            color: #707070;
            font-size: 1rem;
            font-weight: 400;

            &:after {
              left: 0;
            }

            &:before {
              left: 0;
            }

            strong {
              color: #122b28;
              font-weight: bold;
            }
          }

          .value {
            color: #122b28;
            font-size: 1rem;
            font-weight: 400;
          }
        }

        .modal-footer {
          padding: 0;
          border-top: none;
        }
      }
    }

    .current-time {
      color: #122b28;
      font-size: 0.75rem;
      font-weight: 400;
      margin: 0;
    }

    .check-number {
      .check-number-label {
        color: #707070;
        font-size: 16px;
        padding-left: 27px;
      }

      .check-number-label::before {
        border-color: #122b28;
        border-radius: 0;
        left: 0;
        position: absolute;
      }

      .check-number-label::after {
        left: 0;
        position: absolute;
      }
    }
  }
}
// MODAL ENDED//
// CHAT VOICE MESSAGE

.chats {
  .voice-message-tabs {
    border-bottom: none;
    background: none;

    .nav-item {
      flex-grow: 0;
      margin-left: 25px;
    }

    .icon-image {
      margin-right: 5px;
    }

    .nav-link {
      padding: 10px;
      font-size: 0.88rem;
      font-weight: 400;
      color: #122b28;
      border-left: none;
    }
  }

  .voice-message-tab-content {
    .web-record-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;

      .time {
        font-size: 38px;
        font-weight: 300;
        color: #122b28;
        border-bottom: 1px solid #707070;
        max-width: 200px;
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
      }

      .title {
        font-size: 38px;
        font-weight: 300;
        color: #122b28;
      }

      .audioplayer {
        width: 100%;
        max-width: 350px;
      }

      .action-buttons {
        margin-top: 30px;

        .btn {
          background: none;
          border: none;
          border: 1px solid #122b28;
          width: 70px;
          height: 70px;
          line-height: 70px;
          border-radius: 50%;
          text-align: center;
          padding: 0;
          margin-left: 10px;
          margin-right: 10px;

          &.active {
            box-shadow: none;

            i {
              color: #c0392b;
            }
          }

          i {
            font-size: 50px;
            line-height: 70px;
          }
        }

        p {
          margin: 0;
          font-size: 12px;
          font-weight: 500;
          color: #122b28;
          transform: translate(0, -35px);
        }
      }

      .voice-recording-form {
        width: 100%;
        max-width: 350px;

        .form-control {
          border: 1px solid #e4e4e4;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 11px;

          ::placeholder {
            color: #707070;
          }

          &:focus {
            border: 1px solid #e4e4e4;
          }
        }

        .label {
          margin: 65px 0 10px;
          font-size: 1rem;
          font-weight: 300;
          color: #122b28;
        }
      }

      .send-broadcast {
        margin-top: 70px;
        display: flex;
        align-items: center;

        h4 {
          margin: 0;
          color: #122b28;
          font-size: 1.25rem;
          font-weight: bold;
          margin-right: 30px;
        }

        a {
          color: #c0392b;
          font-size: 20px;
          font-weight: 400;

          strong {
            color: #122b28;
          }

          span {
            color: #122b28;
          }
        }
      }

      .content {
        width: 100%;
        max-width: 350px;

        .subtitle {
          font-size: 1.13rem;
          margin-top: 40px;
        }

        p {
          font-size: 1rem;
          color: #707070;
          margin: 0;
        }

        .dropdown-toggle.arrow-left {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          background: transparent;

          &:focus {
            outline: 0;
            box-shadow: none;
          }
        }

        .dropdown-menu {
          min-width: 20px;
        }
      }
    }

    .date-picker-wrapper {
      margin-bottom: 0;
      margin-right: 10px;
      margin-left: auto;
    }

    .date-picker-wrapper .input-group-addon {
      width: 50px;
      height: 50px;
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    .date-picker-wrapper input.form-control {
      height: 0 !important;
      width: 0;
      padding: 0;
      border: none;
      opacity: 0;
    }

    .date-picker-wrapper .input-group-addon img {
      width: 32px;
    }

    .voice-template-content {
      .table {
        color: #707070;

        thead {
          th {
            font-weight: 400;
            font-size: 0.88rem;
            border-bottom: 1px solid #d3d3d3;
            border-top: none;
          }
        }

        tbody {
          tr {
            &:hover {
              background: #fff;

              td {
                .action-button {
                  visibility: visible;
                }
              }
            }

            td {
              font-size: 0.88rem;
              font-weight: 400;
              border-bottom: 1px solid #d3d3d3;
              vertical-align: middle;

              .audioplayer {
                transform: translate(0, 3px);
              }

              .action-button {
                padding: 4px 14px;
                visibility: hidden;
                margin-left: 2px;
                transition: all 0.02s ease-in-out;
              }
            }
          }
        }
      }
    }
  }
  // .nav-tabs{
  //   border-bottom: none;
  // }
  .groups-message {
    max-width: 100%;
  }

  .voice-call {
    width: 100%;
    margin-top: 36px;

    .dailed-number {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 7px;
      border-bottom: 1px solid #707070;

      input {
        border: none;
        background: none;
        flex-grow: 1;
        width: 100%;
        height: 40px;
        font-size: 1.75rem;
        font-weight: 300;
        padding-right: 20px;
        color: #122b28;
      }

      button {
        padding: 0;
        border: 1px solid $primary;
        background: none;
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        color: $primary;

        .material-icons {
          line-height: 30px;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    .dail-pad {
      width: 100%;
      max-width: 290px;
      margin: 15px auto 0;

      .dial-pad-btn span {
        font-size: 0.56rem;
        font-weight: 500;
      }

      .dial-pad-btn:focus {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .dail-pad .dial-pad-btn {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      outline: 0;
      border: 1px solid #122b28;
      background: transparent;
      margin-top: 5px;
      font-size: 1.94rem;
      color: #122b28;
      font-weight: 300;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 1;
      margin-left: auto;
      margin-right: auto;

      &.dial-action-button {
        background: #c0392b;
        color: #fff;
        border: 1px solid #c0392b;
        margin-top: 30px;
      }
    }
  }
}

.chat-action-popover {
  border: none;
  margin-left: -35px;

  .wrapper {
    display: flex;
    align-items: center;
  }

  a {
    display: inline-block;
    margin-right: 12px;
  }

  label {
    white-space: nowrap;

    &:before {
      left: 0 !important;
    }
  }
}
// RIGHT INFO MODAL

.right-info-trigger {
  color: $primary;
  display: flex;
  align-items: center;

  &:focus {
    color: $primary;
  }

  i {
    font-size: 32px;
    line-height: 1;
  }

  &:hover {
    color: $primary;
  }

  &:active {
    color: $primary;
  }
}

.right-info-modal {
  &.fade .modal-dialog {
    transform: translate(100%, 0);
  }

  &.show .modal-dialog {
    transform: translate(0, 0);
  }

  .modal-dialog {
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    width: 100%;
    max-width: 300px;
    bottom: 0;
    background: #fff;
    margin: 0;

    .modal-content {
      height: 100%;

      .nav-tabs .nav-item .nav-link {
        padding: 11px !important;
      }

      .tab-content {
        overflow-y: scroll !important;
      }

      .nav-tabs .nav-item.show .nav-link,
      .nav-tabs .nav-link.active {
        border-color: transparent;
        background: none;
      }

      &.chat-sidebar {
        width: 100% !important;
        @media (max-width: 1200px) {
          width: 100% !important;
        }

        .chat-contents {
          position: static;

          .table-content {
            position: relative;
          }
        }
      }

      .chat-sidebar {
        width: 100% !important;
        @media (max-width: 1200px) {
          width: 100% !important;
        }
      }
    }
  }
}
// LEFT INFO MODAL

.left-info-trigger {
  color: #fff;
  background: $primary;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  text-align: center;
  margin-right: 14px;

  &:focus {
    color: #fff;
  }

  &:hover {
    color: #fff !important;
  }

  &:active {
    color: #fff !important;
  }

  i {
    font-size: 26px;
    line-height: 31px;
  }
}

.left-info-modal {
  &.fade .modal-dialog {
    transform: translate(-100%, 0);
  }

  &.show .modal-dialog {
    transform: translate(0, 0);
  }

  .modal-dialog {
    position: fixed;
    top: 0;
    right: auto;
    left: 0;
    width: 100%;
    max-width: 300px;
    bottom: 0;
    background: #fff;
    margin: 0;

    .modal-content {
      height: 100%;

      .nav-tabs .nav-item .nav-link {
        padding: 11px !important;
      }

      .tab-content {
        overflow-y: scroll !important;
      }

      .nav-tabs .nav-item.show .nav-link,
      .nav-tabs .nav-link.active {
        border-color: transparent;
        background: none;
      }

      &.chat-sidebar {
        width: 100% !important;
        @media (max-width: 1200px) {
          width: 100% !important;
        }

        .chat-contents {
          position: static;

          .table-content {
            position: relative;
          }
        }
      }

      .chat-sidebar {
        width: 100% !important;
        @media (max-width: 1200px) {
          width: 100% !important;
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn-with-tooltip {
  &.disabled {
    cursor: default;
    color: #fff;
    background-color: #5517c1;
    border-color: #5517c1;
    pointer-events: auto;
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}
