.new-message-modal {
  .modal-dialog {
    max-width: 900px;
  }

  .modal-content {
    max-height: 500px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    border: none;
    padding: 15px 15px 0 15px;
    @media (max-width: 600px) {
      max-height: 600px;
    }
  }
  .modal-body {
    overflow: hidden;
  }
  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
    padding-right: 0;
    @media (max-width: 600px) {
      padding-top: 10px;
    }

    .btn {
      min-width: 90px;
    }
  }
  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }
  .search-wrapper-outer {
    display: flex;
  }
  .message-tab {
    border: none;
    background-color: #fff;
    padding: 0;
    .message-single {
      background-color: #fff;
      padding: 13px 0;
      cursor: pointer;
    }
  }
  .people-count {
    color: #122b28;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .people-count-detail {
    margin-bottom: 30px;
    @media (max-width: 600px) {
      margin-bottom: 10px;
    }

    .single {
      align-items: center;
      color: #b4b4b4;
      display: inline-flex;
      margin-right: 20px;

      .icon {
        margin-left: 8px;
      }
    }
  }

  .selected-people {
    margin-bottom: 20px;
    overflow: auto;
    max-height: 240px;
    @media (max-width: 600px) {
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
      height: 80px !important;
      overflow-y: scroll !important;
    }

    .single {
      align-items: center;
      background: #f8f8f8;
      border: 1px solid #dbdbdb;
      border-radius: 5px;
      color: #122b28;
      display: inline-flex;
      height: 24px;
      margin-bottom: 6px;
      margin-right: 4px;
      padding: 0 8px;
      box-shadow: none;
      float: left;
      max-width: calc(50% - 11px);

      &.group {
        transform: translate(0, 2px);
      }
    }

    .avatar {
      height: 14px;
      min-width: 14px;
      width: 14px;
    }

    .user-icon {
      margin-left: 6px;
    }

    .chip-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .close-icon {
      color: #122b28;
      font-size: 12px;
      margin-left: 14px;
    }
  }
}
