.choose-organization {
  .modal-content {
    background: #fff;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    max-width: 380px;
    min-height: 420px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    max-height: 90vh;

    .modal-header {
      border-bottom: 0;
      padding: 30px 25px 15px;
    }

    .modal-body {
      padding: 15px 25px;
      overflow-y: auto;
    }

    .modal-title {
      color: #122b28;
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;
      text-align: center;
    }

    .custom-radio {
      border-bottom: 1px solid #d3d3d3;
      cursor: default;
      padding-bottom: 13px;
      padding-left: 0;
      padding-top: 13px;

      &:last-child {
        border-bottom: 0;
      }

      .name {
        color: #122b28;
        font-size: 0.88rem;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .role {
        color: #707070;
        font-size: 0.88rem;
        margin: 0;
      }
    }

    .custom-control-label {
      cursor: pointer;
    }

    .custom-control-label::before {
      display: none;
      height: 17px;
      width: 17px;
    }

    .custom-radio .custom-control-label::before {
      border-color: $primary;
      top: 50%;
      transform: translate(0, -50%);
      display: none;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      background-color: $primary;
      background-image: none;
      border-radius: 50%;
      height: 10px;
      left: -21px;
      top: 50%;
      transform: translate(0, -50%);
      width: 10px;
      display: none;
    }
  }

  .modal-footer {
    background: #fff;
    border-top: 0;
    padding: 15px 25px;
    text-align: center;
  }
}
