:root {
  --top-bars-height: 0px;
}

body,
html {
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  overflow: hidden;
  -webkit-box-shadow: 0 0 0 1000px white inset;

  input:-webkit-autofill,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  input:-webkit-autofill:hover,
  select:-webkit-autofill,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:hover,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
    -webkit-text-fill-color: #122b28;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

a {
  color: $primary;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}
// popbox

.popbox {
  &.popbox-top-alert {
    background: #bfe9ff !important;
    width: 75%;
    height: auto;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .detail {
      font-size: 16px;
      text-align: center;
    }
  }
}
// RESPONSIVE
@media (max-width: 600px) {
  .desktop-view {
    display: none;
  }
}
@media (min-width: 992px) {
  .tablet-view {
    display: none;
  }
}
@media (min-width: 601px) {
  .mobile-view {
    display: none;
  }
}
// MODALS

.modal {
  z-index: 99999;
}

.modal-dialog {
  height: calc(100% - 16px);
}

// tooltip

span[data-tooltip="tooltip"] {
  align-items: center;
  display: flex !important;
}

.tooltip {
  z-index: 9999999;

  &.show {
    opacity: 1;
  }
  @media (max-width: 991px) {
    &:not(.show-on-mobile-too) {
      display: none;
    }
  }

  .tooltip-inner {
    background: #ffffff;
    border: 1px solid #acacac;
    color: #122b28;
    font-size: 11px;
    line-height: 16px;
    padding: 0 8px;

    .warning {
      color: #fc7e00;
    }
  }

  ul {
    margin: 5px 0;
    padding-left: 24px;
    text-align: left;

    li {
      margin-left: -10px;

      span {
        position: relative;
        left: -5px;
      }
    }
  }
}
@media (max-width: 991px) {
  .show-on-mobile-too .tooltip {
    display: block;
  }
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #acacac;
  top: 1px;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::after,
.bs-tooltip-bottom .arrow::after {
  border-color: transparent;
  border-bottom-color: #fff;
  border-style: solid;
  border-width: 0 0.3rem 0.3rem;
  bottom: -1px;
  content: "";
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #acacac;
  bottom: 1px;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::after,
.bs-tooltip-top .arrow::after {
  border-color: transparent;
  border-style: solid;
  border-top-color: #fff;
  border-width: 0.4rem 0.4rem 0;
  content: "";
  left: 50%;
  position: absolute;
  top: -1px;
  transform: translate(-50%, 0);
}
// search-box

.search-box {
  flex-grow: 1;
  margin-right: 5px;
  max-width: 386px;

  .input-group {
    background-color: #f5f5f5;
    border: 1px solid #e4e4e4;
    border-radius: 22px;

    ::placeholder {
      color: #707070;
    }

    .form-control {
      font-size: 1rem;
      font-weight: 400;
    }
  }
}
// avatar

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 400;
  color: #fff;
  margin-right: 10px;
  min-width: 40px;
  background: $primary;
  overflow: hidden;

  &.number {
    border: 1px solid #707070;
    height: 32px;
    line-height: 32px;
    color: #707070;
    font-size: 1rem;
    font-weight: 400;
    min-width: 32px;
    width: auto;
    border-radius: 16px;
    padding: 0 10px;

    &.duplicate {
      color: #c0392b;
      border: 1px solid #c0392b;
      box-shadow: 4px 0 0 #c0392b;
    }
  }

  &.green {
    background: #abce68;
  }

  &.orange {
    background: #fa8d0e;
  }

  &.teal {
    background: #20cb96;
  }

  &.purple {
    background: #db83e6;
  }

  &.blue {
    background: #73baef;
  }

  &.ac-1 {
    background: #ff912a;

    &.light {
      background: rgba(32, 203, 150, 0.5);
    }

    svg path {
      fill: #ff912a;
    }
  }

  &.ac-2 {
    background: #91d63d;

    &.light {
      background: rgba(145, 214, 61, 0.5);
    }

    svg path {
      fill: #91d63d;
    }
  }

  &.group-avatar {
    background: #6c82bc;

    svg path {
      fill: #6c82bc;
    }
  }

  &.ac-3 {
    background: #4bbc2f;

    svg path {
      fill: #4bbc2f;
    }

    &.light {
      background: rgba(75, 188, 47, 0.5);
    }
  }

  &.ac-4 {
    background: #068d7f;

    &.light {
      background: rgba(6, 141, 127, 0.5);
    }

    svg path {
      fill: #068d7f;
    }
  }

  &.ac-5 {
    background: #2acaf6;

    &.light {
      background: rgba(42, 202, 246, 0.5);
    }

    svg path {
      fill: #2acaf6;
    }
  }

  &.ac-6 {
    background: #48a3eb;

    &.light {
      background: rgba(72, 163, 235, 0.5);
    }

    svg path {
      fill: #48a3eb;
    }
  }

  &.ac-7 {
    background: #465ab1;

    &.light {
      background: rgba(70, 90, 177, 0.5);
    }

    svg path {
      fill: #465ab1;
    }
  }

  &.ac-8 {
    background: #7a5fbc;

    &.light {
      background: rgba(122, 95, 188, 0.5);
    }

    svg path {
      fill: #7a5fbc;
    }
  }

  &.ac-9 {
    background: #ae6fc2;

    &.light {
      background: rgba(174, 111, 194, 0.5);
    }

    svg path {
      fill: #ae6fc2;
    }
  }

  &.ac-10 {
    background: #ee467b;

    &.light {
      background: rgba(238, 70, 123, 0.5);
    }

    svg path {
      fill: #ee467b;
    }
  }

  &.ac-11 {
    background: #ff5d34;

    &.light {
      background: rgba(255, 93, 52, 0.5);
    }

    svg path {
      fill: #ff5d34;
    }
  }

  &.ac-12 {
    background: #bce68a;

    &.light {
      background: rgba(188, 230, 138, 0.5);
    }

    svg path {
      fill: #bce68a;
    }
  }

  &.ac-13 {
    background: #a4dd96;

    &.light {
      background: rgba(164, 221, 150, 0.5);
    }

    svg path {
      fill: #a4dd96;
    }
  }

  &.ac-14 {
    background: #81c5be;

    &.light {
      background: rgba(129, 197, 190, 0.5);
    }

    svg path {
      fill: #81c5be;
    }
  }

  &.ac-15 {
    background: #7edff9;

    &.light {
      background: rgba(126, 223, 249, 0.5);
    }

    svg path {
      fill: #7edff9;
    }
  }

  &.ac-16 {
    background: #90c7f2;

    &.light {
      background: rgba(144, 199, 242, 0.5);
    }

    svg path {
      fill: #90c7f2;
    }
  }

  &.ac-17 {
    background: #a1abd7;

    &.light {
      background: rgba(161, 171, 215, 0.5);
    }

    svg path {
      fill: #a1abd7;
    }
  }

  &.ac-18 {
    background: #bbaedd;

    &.light {
      background: rgba(187, 174, 221, 0.5);
    }

    svg path {
      fill: #bbaedd;
    }
  }

  &.ac-19 {
    background: #f48faf;

    &.light {
      background: rgba(244, 143, 175, 0.5);
    }

    svg path {
      fill: #f48faf;
    }
  }

  &.ac-20 {
    background: #ff9d84;

    &.light {
      background: rgba(255, 157, 132, 0.5);
    }

    svg path {
      fill: #ff9d84;
    }
  }
}
// button

.primary-button {
  background: $primary;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid $primary;
  padding: 9px 20px;

  &.button-small {
    padding: 7px 22px;
    display: inline-block;
  }

  &:focus {
    outline: 0;
  }
}

.red-button {
  background: #c0392b;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #c0392b;
  padding: 9px 20px;

  &.button-small {
    padding: 7px 22px;
    display: inline-block;
  }

  &:focus {
    outline: 0;
  }
}

.outline-button {
  background: none;
  border-radius: 5px;
  color: #122b28;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #122b28;
  padding: 9px 20px;

  &:focus {
    outline: 0;
  }
}
// COLOR

svg.color-secondary {
  fill: $secondary;
}
// CHECKBOX

.custom-checkbox {
  min-height: 1px;
  padding-left: 0;

  .custom-control-label::after,
  .custom-control-label::before {
    position: absolute;
    width: 18px;
    height: 18px;
    left: auto;
    top: 50%;
    transform: translate(0, -50%);
  }

  .custom-control-label {
    font-size: 12px;
    font-weight: 400;
    color: #122b28;
  }

  .custom-control-label::before {
    background: transparent;
    border: 1px solid $primary;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
  border: 1px solid $primary;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("../img/icons/checked-icon.svg");
  background-size: 59%, 59%;
}

.no-padding {
  padding: 0 !important;
}

.no-boder {
  border: none;
}

.pl-30 {
  padding-left: 30px !important;
}
// Popover

.popover {
  position: static;
}

// CUSTOM COLLAPSE

.custom-collapse {
  .arrow-r {
    position: relative;

    &:after {
      content: "\f0d7";
      font-family: "FontAwesome";
      display: inline-block;
    }
  }

  .arrow-d {
    position: relative;

    &:after {
      content: "\f0d8";
      font-family: "FontAwesome";
      display: inline-block;
    }
  }
}

// Material Input

.input-field {
  &.material-style {
    margin-top: 40px;
    position: relative;

    ::placeholder {
      opacity: 0;
    }

    input {
      color: $color-text;
      font-family: $poppins;
      font-size: 1.38rem !important;

      &:focus {
        border-bottom: 1px solid $link;

        + label {
          color: #8f8f8f;
          font-size: 0.63rem !important;
          top: -15px;
          pointer-events: none;
        }
      }

      &:valid {
        border-bottom: 1px solid $link;

        + label {
          color: #8f8f8f;
          font-size: 0.63rem !important;
          top: -15px;
          pointer-events: none;
        }
      }

      &.input-has-value {
        border-bottom: 1px solid $link;

        + label {
          color: #8f8f8f;
          font-size: 0.63rem !important;
          top: -15px;
          pointer-events: none;
        }
      }
    }

    label {
      align-items: center;
      box-sizing: border-box;
      color: #b4b4b4;
      cursor: text;
      display: flex;
      font-family: $poppins;
      font-size: 1.38rem !important;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.2s ease-in-out;
    }
  }
}
// popbox modal

.iziModal-overlay {
  display: none;
  opacity: 0 !important;
}

.iziModal {
  .popbox-content {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
  }

  .modal-header {
    border-bottom: 0;
    flex-direction: column;

    .search-wrapper {
      margin-top: 25px;
      width: 100%;

      &.focused {
        border-color: $primary;
      }
    }
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .custom-checkbox {
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    padding: 15px 3px;

    &:last-child {
      border-bottom: none;
    }

    .custom-control-label {
      font-size: 0.94rem;
      font-weight: 500;
      padding-left: 32px;

      &:after {
        border-radius: 0;
        left: 0;
      }

      &:before {
        border-radius: 0;
        left: 0;
      }

      .number {
        color: #b4b4b4;
        font-weight: 400;
        margin-left: 10px;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background: $primary;
    }
  }

  .modal-footer {
    border-top: 0;

    .btn {
      min-width: 90px;
    }
  }
}

svg.icon-white {
  path,
  rect,
  circle {
    fill: #fff !important;
  }
}

svg.icon-black,
.avatar.ac-2 svg.icon-black {
  path:not([fill="none"]),
  rect:not([fill="none"]),
  circle:not([fill="none"]) {
    fill: $color-text !important;
  }
}

svg.icon-grey {
  path:not([fill="none"]),
  rect:not([fill="none"]),
  circle:not([fill="none"]) {
    fill: #b4b4b4 !important;
  }
}

svg.icon-grey2 {
  path,
  rect,
  circle {
    fill: #909090 !important;
  }
}

svg.icon-grey3 {
  path,
  rect,
  circle {
    fill: #95a5a6 !important;
  }
}

svg.icon-grey-dark {
  path:not([fill="none"]),
  rect,
  polyline {
    fill: #565656 !important;
  }
}

svg.icon-muted {
  path,
  rect,
  circle {
    fill: $text-muted !important;
  }
}

svg.icon-primary {
  path:not([fill="none"]),
  rect:not([fill="none"]),
  circle:not([fill="none"]) {
    fill: $primary !important;
  }
}

svg.icon-green {
  path,
  rect,
  circle {
    fill: #00c186 !important;
  }
}

.btn-primary,
.btn-outline-primary {
  &:hover {
    svg.icon-primary {
      path:not([fill="none"]),
      rect:not([fill="none"]) {
        fill: #fff !important;
      }
    }
  }
}

svg.icon-secondary {
  path,
  rect,
  circle {
    fill: $secondary !important;
  }
}

svg.icon-blue {
  path,
  rect,
  circle {
    fill: #73baef !important;
  }
}

svg.icon-madc {
  path,
  rect,
  circle {
    fill: #a574bd !important;
  }
}
// When-top-alert-exists

.with-alert {
  .sidebar {
    top: 100px;
  }

  .header {
    margin-top: 40px;
  }

  .page-wrapper {
    top: 40px;
  }

  .message-tab .message-list {
    padding-bottom: 100px;
  }
}

.hover-stroke-svg {
  &:hover {
    path {
      stroke: $primary;
      stroke-width: 0.5px;
    }
  }
}

.gray-color {
  color: #797878 !important;
  stroke: #797878 !important;
  border-color: #797878 !important;
}

.light-gray-color {
  color: #b4b4b4 !important;
  stroke: #b4b4b4 !important;
  border-color: #b4b4b4 !important;
}
