.chat-empty {
  color: #707070;
  font-size: 1.5rem;
  font-weight: 300;
  left: 50%;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}
