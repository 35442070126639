.confirm-group-message {
  .modal-content {
    padding-top: 60px !important;
    .close {
      opacity: 1;
      outline: 0;
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
  .modal-body {
    .title {
      line-height: 20px;
      margin-bottom: 60px;
      h4 {
        line-height: 20px;
        margin: 0 !important;
      }
      a {
        line-height: 20px;
      }
    }
  }
  .modal-footer {
    .btn {
      align-items: center;
      display: inline-flex;
      height: 44px;
      justify-content: center;
      min-width: 90px;
      padding: 0 20px;
    }
    .btn-outline-primary {
      &:hover {
        background: rgba($primary, 0.1);
        color: $primary;
      }
    }
  }
}
