.new-version-available-modal-wrapper {
  .themed-modal {
    background: none !important;
  }
  .modal-content {
    box-shadow: none !important;
    border: 0;
  }
  .image {
    width: 100%;
    height: 100%;
    box-shadow: 0px 5px 6px #00000038;
  }
  .modal-header {
    border-bottom: 0;
  }
  .close-icon {
    top: 15px;
    right: 15px;
    width: max-content;
    background: none;
    border: none;
    position: absolute;
  }
  .download-button {
    position: relative;
    width: 266px;
    height: 37px;
    bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    text-align: center;
    border: none;
    font-weight: 400;
    min-width: max-content;
    background-color: #bb86fc;
    font-size: 16px;
    padding: 6px 15px 6px 15px;
  }
}
