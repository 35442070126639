// Panel tabs

.right-panel {
  .fixed-header {
    padding: 0;

    .nav-tabs {
      min-height: 47px;
      background: $primary-light;

      .back-icon {
        align-items: center;
        display: flex;
        height: 48px;
        padding: 0 15px;
        position: absolute;
      }

      .nav-link {
        height: 46px;
        padding: 0 15px;
        color: #fff;
        display: inline-flex;
        align-items: center;
        background: none;
        border: none;
        opacity: 0.5;

        &.active {
          background: none;
          border: none;
          opacity: 1;

          svg path {
            fill: rgba(255, 255, 255, 1);
          }
        }

        svg path {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
