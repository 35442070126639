.notifications {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 99009;
}

.toast {
  border-width: 0;
  border-radius: 6px;
}

.toast-header {
  background-color: #6c37c9;
  color: #ffffff;
  border-bottom: none;
}

.toast-body {
  background-color: #5517c1;
  color: #ffffff;
}
