.dropdown-menu {
  &.notifications {
    min-width: 300px;
    padding: 0;
    .notification-message {
      list-style: none;
    }
    .notification-list {
      margin: 0;
      padding: 0;
      max-width: 300px;
      min-width: 200px;
    }
    .message-single {
      display: flex;
      padding: 13px 15px;

      &.active {
        background: #fff;
        border-bottom: 1px solid $primary-light;
        border-top: 1px solid $primary-light;
      }

      h3.name {
        display: block;
        padding-top: 0;
      }

      &.unread {
        .message-text {
          font-weight: 500;
          color: #122b28;
        }

        h3 {
          align-items: center;
          display: flex;
          font-size: 1rem;
          justify-content: space-between;
          margin: 0;
        }
      }

      &.undelivered {
        h3 {
          display: flex;
          margin: 0;
          font-size: 1rem;
          align-items: center;
          justify-content: space-between;

          .undelivered {
            font-size: 1rem;
            color: $secondary;

            &.cleared {
              color: #dbdbdb;
            }
          }
        }
      }

      .avatar {
        width: 36px;
        min-width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 10px;
        font-size: 1.06rem;
        color: #fff;
        font-weight: 400;
      }

      .detail {
        overflow: auto;
        overflow-y: hidden;
        flex-grow: 1;
      }

      h3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding-top: 3px;
        line-height: 1;
        white-space: nowrap;

        .date {
          font-size: 0.81rem;
          display: flex;
          align-items: center;
          color: #707070;
          margin-top: 1px;
          font-weight: 400;

          i {
            font-size: 0.75rem;
            margin-right: 5px;
          }
        }
      }

      .name {
        margin: 0;
        color: #122b28;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        i {
          color: $color-text;
          font-size: 1rem;
          margin-left: 5px;
        }

        .icon {
          margin-left: 5px;
        }
      }

      .number {
        margin: 0;
        font-weight: 300;
        font-size: 0.94rem;
        margin-top: 8px;
        color: $color-text;
      }

      .message-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info {
          display: flex;
          align-items: center;
          margin-top: 6px;

          i,
          span {
            margin-left: 10px;
          }

          i {
            font-size: 0.75rem;
          }

          span {
            font-weight: 600;
            font-size: 0.81rem;
          }
        }
      }

      .message-text {
        color: #707070;
        font-size: 0.81rem !important;
        margin: 0;
        margin-top: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .icon {
          margin-right: 7px;
          vertical-align: middle;
        }
      }
    }

    .topnav-dropdown-header {
      border-bottom: 1px solid #e1e1e1;
      font-size: 0.81rem;
      height: 36px;
      line-height: 36px;
      padding: 0 24px;
    }

    .topnav-dropdown-footer {
      border-bottom: 1px solid #e1e1e1;
      font-size: 0.81rem;
      height: 36px;
      line-height: 36px;
      padding: 0 24px;
      text-align: right;
      a {
        color: $primary;
      }
    }
  }
}
