.modal-with-action-menus {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    .modal-content {
      height: auto;
      min-height: auto;
    }
  
    .modal-with-action-menus-container {
      padding: 15px 30px;
      position: relative;
    }
  
    .action-menus-wrapper {
      width: 45%;
      margin-left: 15px;
      position: absolute;
      margin-top: 20px;
      @extend .modal-content;
      height: auto;
      min-height: auto;
      .action-menu {
        font-size: 16px;
        padding: 7px 20px;
        border-bottom: 1px solid #d7d7d7;
        cursor: pointer;
        &:last-child {
          border-bottom: none;
        }
        &:first-child {
          margin-top: 5px;
        }
      }
    }
  }
  