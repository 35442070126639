#globalModal,
.global-modal {
  z-index: 999999;

  .modal-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 30px;
    .close {
      position: absolute;
      right: 15px;
      top: 15px;
    }
    .modal-title {
      color: #122b28;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }
  .modal-content {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    padding: 30px 25px 27px 30px;
    border: none;
  }
  .modal-body {
    padding: 0;
    p {
      color: #707070;
      font-size: 16px;
      font-weight: 400;
    }
    a {
      color: #411293;
      text-transform: uppercase;
    }
  }
  .modal-footer {
    border-top: none;
    padding: 0;
    margin-top: 40px;

    .btn {
      align-items: center;
      display: inline-flex;
      height: 44px;
      justify-content: center;
      min-width: 90px;
      padding: 0 20px;
    }
    .btn-outline-primary {
      &:hover {
        background: rgba($primary, 0.1);
        color: $primary;
      }
    }
  }
}

.image-warning-footer-wrapper {
  @media (max-width: $screen-sm) {
    flex-direction: column;
    margin-top: 0px !important;
  }
  .text-nowrap {
    @media (max-width: $screen-sm) {
      margin-top: 8px;
    }
  }
}