.sidebar-item-inbox {
  a {
    .icon-white,
    .icon-black {
      width: 25px !important;
      max-width: 25px !important;
      max-height: 22px !important;
      margin-right: 0px !important;
      position: static !important;
      vertical-align: middle !important;
      margin-top: 19px !important;
    }
    .icon-white {
      display: none;
    }
    .icon-black {
      display: inline-block;
    }

    &:hover,
    &:focus,
    &:active {
      .icon-white {
        display: inline-block;
      }
      .icon-black {
        display: none;
      }
    }
  }
}
