.number-filter-modal {
  .modal-content {
    background: #fff;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    max-width: 380px;
    min-height: 420px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
    max-height: 90vh;

    .modal-header {
      border-bottom: 0;
      padding: 30px 25px 15px;
    }

    .modal-body {
      padding: 15px 25px;
      overflow-y: auto;
    }

    .modal-title {
      color: #122b28;
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;
      text-align: center;
    }
  }

  .custom-control-label {
    width: 100%;

    .content > div:last-child {
      width: calc(100% - 24px);
    }
  }

  .custom-switch {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 13px;
    padding-top: 13px;

    &:last-child {
      border-bottom: 0;
    }

    .content {
      padding: 0 !important;
    }

    .custom-control-label::before {
      background: rgba(180, 180, 180, 0.4);
      border: 0;
      height: 16px;
      top: 50%;
      transform: translate(0, -50%);
      width: 31px;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background: rgba(85, 23, 193, 0.4);
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background: $primary;
      transform: translateX(0.85rem) translateY(-50%);
    }

    .custom-control-label::after {
      background: #fff;
      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.16));
      height: 13px;
      // top: calc(0.25rem + 1.5px);
      width: 13px;
      top: 50%;
      transform: translate(0, -50%);
    }

    p {
      margin: 0;

      &.name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #707070;
      }

      &.number {
        color: #122b28;
        font-weight: 500;
      }
    }

    .user-icon {
      margin-top: 10px;
    }
  }

  .modal-footer {
    background: #fff;
    border-top: 0;
    padding: 15px 25px;
    text-align: center;
  }
}
