.make-new-modal {
  .modal-body a {
    color: #122b28;
    text-transform: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 48px;
      font-size: 0.88rem;
    }

    .icon {
      display: inline-block;
      width: 32px;
      padding-right: 4px;
      text-align: center;

      svg {
        max-width: 20px;
        max-height: 18px;
      }
    }
  }
}
