body.message-with-schedule {
  .main-wrapper.user-thread {
    bottom: 94px;
  }
}

body.chat-typing {
  .main-wrapper.user-thread {
    bottom: 98px;
  }
}

body.message-with-schedule.chat-typing {
  .main-wrapper.user-thread {
    bottom: 142px;
  }
}

@keyframes scheduled-highlight {
  0% {
    background: #797878;
  }

  50% {
    background: #9b9898;
  }

  100% {
    background: #797878;
  }
}

.chat-thread {
  bottom: 0;
  padding: 15px 20px 0;
  top: 56px;

  .new-message {
    align-items: center;
    background: $secondary;
    border-radius: 19px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    display: flex;
    height: 36px;
    justify-content: space-between;
    left: 50%;
    padding: 9px 12px;
    position: sticky;
    top: calc(100% - 50px);
    transform: translate(-50%, 0);
    width: 235px;
    z-index: 999;

    a {
      align-items: center;
      color: #fff;
      display: flex;
      font-size: 13px;
      transition: none;
    }

    span {
      font-size: 15px;
    }

    .icon {
      margin-right: 10px;
    }
  }

  .filter-info {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 16px 0;

    .icon {
      line-height: 0;
      margin-right: 10px;
    }

    p {
      color: #8f8f8f;
      font-size: 16px;
      margin: 0;
    }
  }

  .user-thread-single {
    display: flex;
    margin-bottom: 25px;
    position: relative;

    > div {
      max-width: 80%;
    }

    .expand-message {
      position: absolute;
      width: 24px;
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      transition: opacity 150ms ease;
      height: 24px;
      color: #8f8f8f;
      margin-right: -30px;
      top: 15px;
      &.isPressed {
        top: 30px;
        visibility: visible;
        opacity: 1;
      }
    }

    &:hover {
      .expand-message {
        visibility: visible;
        opacity: 1;
      }
    }

    &.incoming {
      justify-content: flex-start;

      .message-box {
        background: #f2f2f2;
        width: 100%;
      }

      .audioplayer-time {
        color: #122b28;
      }

      .audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
        border-left-color: #122b28;
      }

      .audioplayer-playing .audioplayer-playpause a:after,
      .audioplayer-playing .audioplayer-playpause a:before {
        background-color: #122b28;
      }

      .audioplayer-volume-button {
        background-color: #eaebeb;
      }

      .audioplayer-volume-button a {
        background-color: #122b28;

        &::before {
          border-right-color: #122b28;
        }

        &::after {
          border-color: #122b28;
        }
      }

      .audioplayer-bar-played {
        background-color: rgba($primary, 0.3);
      }
    }

    &.outgoing {
      margin-left: 30px;
      padding-right: 40px;
      justify-content: flex-end;

      &.internal {
        .message-box {
          background: #fff5c1;
        }
      }

      > div {
        text-align: right;
      }
      @media (max-width: 600px) {
        margin-left: 0;
      }

      &.pressed {
        .avatar {
          bottom: 30px;
        }
      }

      &.is-failed {
        .avatar {
          bottom: 30px;
        }
      }

      .audioplayer-time {
        color: #122b28;
      }

      .audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
        border-left-color: #122b28;
      }

      .audioplayer-playing .audioplayer-playpause a:after,
      .audioplayer-playing .audioplayer-playpause a:before {
        background-color: #122b28;
      }

      .audioplayer-volume-button {
        background-color: #ebebff;
      }

      .audioplayer-volume-button a {
        background-color: #122b28;

        &::before {
          border-right-color: #122b28;
        }

        &::after {
          border-color: #122b28;
        }
      }

      .audioplayer-bar-played {
        background-color: rgba($primary, 0.3);
      }

      .message-box {
        background: #ebebff;
        color: #122b28;

        .metadata {
          color: rgba(100, 118, 116, 0.7);
        }

        svg {
          path {
            fill: #122b28;
          }
        }
      }

      .avatar {
        align-items: center;
        background: coral;
        border-radius: 20px;
        bottom: 0;
        color: #fff;
        display: inline-flex;
        font-size: 12px;
        height: 30px;
        justify-content: center;
        position: absolute;
        right: -8px;
        text-align: center;
        width: 30px;
        min-width: 30px;
        // transition: all 0.3s ease-in-out;
      }
    }

    &.processing {
      .avatar {
        bottom: 30px;
      }
    }

    &.scheduled {
      .message-box {
        // inside border
        box-shadow: inset 0 0 0 1px #808080;
        margin-bottom: 15px;
        margin-top: 15px;
        color: #fff;
        background: #797878;
        animation: scheduled-highlight 2000ms ease-out 1;
      }
    }

    .action-btn-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .action-btn {
      color: #122b28;
      font-size: 13px;
      display: inline-block;
      margin-right: 10px;
      margin-top: 5px;

      &:last-child {
        margin-right: 0;
      }

      > span {
        display: inline-block;
        margin-left: 2px;
        padding-top: 2px;
        vertical-align: middle;
      }
    }

    &.pressed {
      .user-info {
        display: flex;
        position: relative;
      }

      .hide-on-press {
        display: none !important;
      }

      .show-on-press {
        display: inline-block !important;

        &.metadata {
          display: flex !important;
        }
      }
    }

    .hide-on-press {
      display: inline-block !important;
    }

    .show-on-press {
      display: none !important;
    }

    .user-info {
      display: none;
      align-items: center;

      .error-icon {
        color: #8f8f8f;
        font-size: 17px;
        margin-right: 5px;
        vertical-align: sub;
      }

      .name {
        color: #8f8f8f;
        font-size: 0.88rem;
        font-weight: 400;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .number {
        color: #8f8f8f;
        font-size: 0.88rem;
        font-weight: 400;
        white-space: nowrap;
      }

      svg {
        margin-left: 10px;
        min-width: 8px;

        path {
          fill: #8f8f8f;
        }
      }

      .arrow-icon {
        color: #8f8f8f;
        transform: rotate(90deg);
        font-size: 14px;
        margin-right: 5px;
      }

      .material-icons {
        margin-bottom: 1px;
        vertical-align: text-bottom;
        font-size: 16px;
      }
    }

    .message-box {
      width: 100%;
      max-width: 415px;
      background: #bfeae6;
      margin-top: 5px;
      padding: 15px;
      border-radius: 10px;
      display: inline-block;
      text-align: left;

      &.unread {
        border: 1px solid #919191;

        .message-body {
          font-weight: 500;
        }
      }

      .audio-player {
        max-width: 100%;
      }

      .message-body {
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        word-break: break-word;
      }

      .metadata {
        font-size: 0.75rem;
        color: #647674;
        margin-top: 5px;
        display: flex;
        align-items: center;
        font-weight: 400;

        i {
          font-size: 13px;
          margin-right: 8px;
        }

        .preloader-wrapper {
          width: 12px;
          height: 12px;
          margin-left: 10px;

          .spinner-layer {
            border-color: #fff;

            .circle {
              border-width: 1px;
            }
          }
        }
      }
    }

    & .text-container {
      p {
        display: inline;
      }
    }
    button.read-more-btn {
      outline: none;
      border: none;
      cursor: pointer;
      background: none;
      color: #664f83;
      font-size: 14px;
      text-decoration: underline;
    }

    .info {
      align-items: center;
      color: #8f8f8f;
      display: flex;
      margin-top: 5px;
      margin-right: auto;
      font-size: 13px;

      &.info-error {
        color: $secondary;
        text-align: left;
        padding-right: 10px;
        padding-top: 2px;
        vertical-align: middle;
        align-items: baseline;

        i {
          top: 2px;
          position: relative;
        }
      }

      i {
        font-size: inherit;
      }

      .action-btn {
        margin-top: 0;
      }
    }

    .failed-info {
      align-items: center;
      color: #8f8f8f;
      display: flex;
      margin-right: auto;
      font-size: 0.88rem;
      min-height: 30px;

      &.new {
        color: $secondary;
      }

      i {
        margin-right: 5px;
        font-size: 14px;
      }
    }
  }

  .scheduled-message {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 15px 0;

    .schedule-header {
      color: #797878;
      border: 1px dotted #797878;
      padding: 5px 8px;
      border-radius: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:after {
      content: "";
      border-top: 0.01em solid #797878;
      position: absolute;
      display: block;
      height: 0.5px;
      top: 50%;
      width: 100%;
      z-index: 1;
    }

    &.date-divider {
      &:after {
        border-top: 0.01em solid #dbdbdb;
      }

      p {
        color: #b4b4b4;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .inner {
      background: #fff;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 9;
    }

    p {
      color: $primary;
      font-weight: 400;
      margin: 0;
      font-size: 14px;
    }
  }

  .hidden-scheduled-message {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 15px 0;
    color: #b4b4b4 !important;

    &:after {
      content: "";
      border-top: 0.01em dashed #bdb4b4;
      position: absolute;
      display: block;
      height: 1px;
      top: 50%;
      width: 100%;
      z-index: 1;
    }

    .inner {
      background: #fff;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: none;
      z-index: 9;

      &.button {
        border: none;
      }

      &.focus,
      &:focus {
        box-shadow: none;
      }

      &.active {
        .hide {
          display: none;
        }

        .show {
          display: inline-flex;

          i {
            color: #b4b4b4;
            margin-left: 10px;
            font-size: 20px;
          }
        }
      }

      .show {
        display: none;
      }
    }

    p {
      margin: 0;
      color: #b4b4b4;
      font-weight: 400;
      font-size: 14px;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-left: 10px;

      path {
        fill: #b4b4b4;
      }
    }
  }
}
//  SCHEDULE MESSAGE TAP INFO

.schedule-message-tap-info.modal {
  background: $primary;

  .modal-content {
    text-align: center;
    padding: 40px 30px;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      line-height: 1.5;
    }

    p {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }
  }
}
// Forward message modals

.modal.forward-message {
  max-height: 90%;
  width: 90% !important;

  .modal-content {
    padding: 50px 25px;

    .title {
      font-size: 20px;
      color: #122b28;
      font-weight: bold;
    }

    .country-number {
      display: flex;

      select {
        width: auto;
        background: none;
        border: none;
      }

      input {
        border: 1px solid #bbb;
        height: 36px;
        border-radius: 18px;
        background: #f0f0f0;
        padding: 0 10px;
        margin: 0;

        &:focus {
          border: 1px solid $primary;
          box-shadow: none;
        }
      }
    }
  }

  .chat-box {
    min-height: 56px;
    height: auto;
    border-top: 1px solid #e1e1e1;
    padding: 5px;
    display: block;

    .top-info {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .left {
        display: flex;
        align-items: center;

        .info-icon {
          color: $secondary;
          margin-right: 10px;
        }

        span {
          font-size: 12px;
          color: #707070;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .select-wrapper {
          width: 120px;
          margin-left: 10px;

          svg {
            fill: $secondary;
          }
        }

        .select-wrapper input.select-dropdown {
          margin: 0;
          border: none;
          color: $secondary;
          font-weight: 500;
        }
      }
    }

    .typing-box {
      display: flex;
      margin-top: 15px;

      textarea {
        border: 1px solid #e4e4e4;
        background: #fff;
        margin: 0;
        padding: 10px;
        height: auto;

        &:focus {
          border: 1px solid $primary;
          box-shadow: none !important;
          outline: 0;
        }
      }

      .send-button {
        min-width: 90px;
        background: $secondary;
        border-radius: 5px;
      }

      ::placeholder {
        color: #707070;
        font-size: 15px;
        font-weight: 500;
      }
    }

    .chat-typing-action {
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 10px;

      .expanded-view {
        display: none;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 99;
        background: #f8f8f8;

        &.expanded {
          display: flex;
        }

        a {
          margin-left: 6px;
        }

        .checkbox-single {
          margin-left: 15px;
        }
      }

      .mini-view {
        display: flex;
        align-items: center;

        .expand-toggle {
          color: $primary;
          margin-right: 10px;
          margin-top: 5px;

          i {
            transform: rotate(90deg);
          }
        }

        p {
          margin: 0;
          font-size: 13px;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
    }
  }
}
// Typing alert

.footer {
  .typing-alert {
    background: #f0f0f0;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -6px -10px 10px;

    &.soft {
      background: #bfe9ff;
    }

    &.medium {
      background: #ffe577;
    }

    &.hard {
      background: #e03737;

      .icon svg {
        path {
          fill: #fff;
        }

        rect {
          fill: #fff;
        }
      }

      p {
        color: #fff;
      }
    }

    P {
      margin: 0;
      font-size: 16px;
      color: #122b28;
    }

    .icon {
      line-height: 0;
      margin-right: 10px;
    }
  }
}
// No credit info

.no-credit-info {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.01);

  &.active {
    display: flex;
  }

  .content {
    background: $primary;
    padding: 50px 15px;
    width: 90%;
    max-width: 280px;
    text-align: center;

    p {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
// User alert

footer.chat-box {
  .alert-button {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    height: 56px;
    line-height: 56px;
    border-radius: 0;
    font-size: 19px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;
      margin-right: 10px;

      &.rotate-90 {
        transform: rotate(90deg);
      }
    }

    svg {
      margin-right: 10px;
    }
  }

  .new-message {
    @media (min-width: 992px) {
      display: none !important;
    }
  }
}

.modal.user-status-modal {
  .modal-content {
    background: $primary;
    padding: 40px 15px;
    text-align: center;

    .icon {
      margin-bottom: 25px;

      i {
        font-size: 30px;
        color: #fff;

        &.rotate-90 {
          transform: rotate(90deg);
        }
      }
    }

    p {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
// Mobile chat thread

.popbox {
  &.chat-thread {
    background: #fff;
    height: 100%;
    padding: 0;
    top: 0;

    .chat-wrap-inner {
      padding: 45px 0 0;
    }

    .thread-info-action {
      align-items: center;
      background: $primary-light;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      color: #fff;
      display: flex;
      height: 45px;
      left: 0;
      padding-left: 17px;
      padding-right: 17px;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 99;

      .avatar {
        height: 30px;
        min-width: 30px;
        width: 30px;
      }

      .name {
        font-size: 1rem;
        font-weight: 500;
        margin-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a {
        color: #fff;
      }

      .dropdown-toggle {
        &:after {
          display: none;
        }
      }

      .dropdown-item {
        align-items: center;
        color: #122b28;
        display: flex;

        &.active {
          background: none;

          .show-on-active {
            display: inline;
          }

          .hide-on-active {
            display: none;
          }
        }

        .show-on-active {
          display: none;
        }

        svg {
          margin-right: 10px;

          path {
            fill: #122b28;
          }
        }
      }
    }

    footer.chat-box {
      background: #fff;
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      z-index: 99;
    }

    .chat-window {
      height: 100%;
    }

    .new-message {
      position: absolute;
      top: -50px;
    }
  }

  &.group-detail-modal {
    .fixed-header {
      border-top: 1px solid #b69be4;
    }

    .chat-wrap-inner {
      padding: 0;
    }

    .back-icon i {
      color: #fff;
    }

    .message_tab {
      display: none;
    }

    .chat-window {
      height: 100%;
    }

    .user-detail-tab {
      padding: 0;
    }

    .table-content {
      position: relative;
      background-color: #f8f8f8;
    }

    .message-single {
      background-color: #f8f8f8;
    }

    .nav-tabs {
      border: 0;

      .nav-item .nav-link {
        margin-left: -20px;
      }
    }

    .back-arrow {
      align-items: center;
      display: flex;
      height: 100%;
      margin-left: 17px;
      padding: 0 !important;

      &:focus {
        background: none;
      }

      &:hover {
        background: none;
      }
    }

    .bg-less {
      background-color: transparent;
      outline: 0;
      border: none;
    }
  }
}
