.message-filter-modal {
  .modal-content {
    background: #fff;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    min-height: 420px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    .modal-header {
      border-bottom: 0;
      padding: 30px 25px 15px;
    }

    .modal-body {
      padding: 15px 25px;
    }

    .modal-title {
      color: #122b28;
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;
      text-align: center;
    }

    a.no-collapise {
      align-items: center;
      border-bottom: 0;
      color: #122b28;
      display: flex;
      font-size: 0.88rem;
      min-height: 48px;

      .number {
        margin-left: auto;
      }

      i {
        display: inline-block;
        font-size: 20px;
        margin-right: 5px;
        text-align: center;
        width: 2rem;

        &.filled {
          color: $secondary;
        }
      }
    }

    .collapsible {
      border: 0;
      box-shadow: none;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .collapsible-header {
      align-items: center;
      border-bottom: 0;
      color: #122b28;
      cursor: pointer;
      display: flex;
      font-size: 0.88rem;
      min-height: 48px;

      .number {
        margin-left: auto;
      }

      i {
        font-size: 20px;
        margin-right: 5px;
        text-align: center;
        width: 2rem;

        &.filled {
          color: $secondary;
        }
      }

      svg {
        margin-right: 5px;
        vertical-align: middle;
      }

      svg.color-secondary path {
        fill: $secondary;
      }

      .fa-angle-down {
        font-size: 12px;
      }
    }

    .collapsible-header[aria-expanded="true"] .fa-angle-down {
      transform: rotate(180deg);
    }

    .collapsible-body {
      background: #f8f8f8;
      border: 1px solid #eaebeb;
      border-left: 0;
      border-radius: 0;
      border-right: 0;
      margin: 0 -25px;
      padding: 10px 25px 10px 50px;

      a {
        align-items: center;
        color: #122b28;
        display: flex;
        font-size: 0.88rem;
        height: 30px;
        margin-left: 20px;

        .number {
          margin-left: auto;
        }
      }
    }
  }

  .modal-footer {
    background: #fff;
    border-top: 0;
    padding: 15px 25px;
    text-align: center;
  }
}
