body.registration {
  font-family: $poppins !important;

  .modal-backdrop {
    display: none;
  }
}

.sticky-info {
  height: 40px;
  background: $primary;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.88rem;
  font-weight: bold;
  color: #fff;

  &.secondary {
    background: $secondary;
  }
}

.input-field-with-edit {
  .input-group {
    padding: 0;

    &.form-control {
      padding: 0;
    }
  }

  .with-icon {
    padding-left: 50px;
    @media (max-width: 768px) {
      padding-left: 25px;
    }
  }
}

.sticky-info + .header + .content {
  max-height: calc(100vh - 130px);
}

.registration-steps {
  min-height: 100vh;

  .owl-carousel {
    position: relative;

    .owl-nav {
      position: fixed !important;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      left: 0;
      right: 0;
      padding: 0 5px;

      span {
        color: $primary;
        font-size: 30px;
      }
    }

    .owl-dots {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 0;
    }

    .owl-dot {
      span {
        height: 4px;
        width: 15px;
        border-radius: 4px;
      }

      &:focus {
        box-shadow: none;
        outline: 0;
      }

      &.active {
        &:focus {
          box-shadow: none;
          outline: 0;
        }

        span {
          background-color: $primary;
        }
      }
    }

    &.owl-theme .owl-nav [class*="owl-"]:hover {
      background: #fff;

      &:focus {
        outline: 0;
      }
    }
  }

  .more-content {
    overflow: hidden;
  }

  .header {
    align-items: center;
    background: linear-gradient(to right, #935ef0, #5517c1);
    border: none;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding: 5px 30px;
    position: sticky;
    top: 0;
    @media (max-width: 768px) {
      padding: 5px 15px;
    }

    .logo {
      line-height: 0;
      margin-left: 0 !important;
      @media (max-width: 600px) {
        .full {
          display: none;
        }

        .icon {
          display: inline-block !important;
        }
      }

      .icon {
        display: none;
      }
    }

    .dropdown-toggle {
      color: #fff;
      font-size: 1rem;
      font-weight: 500;

      &:after {
        display: inline-block;
      }

      &:focus {
        box-shadow: none;
      }

      .avatar {
        color: #3c2fad;
        font-size: 1rem;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        min-width: 30px;
        width: 30px;
        background-color: #fff;
      }
    }

    .dropdown-menu.logout {
      border: 1px solid #707070;
      min-width: 6.88rem;

      a {
        font-size: 0.88rem;
        font-weight: 500;

        &:active {
          background: none;
          color: #122b28;
        }
      }
    }
  }

  .input-group-prepend.show {
    button {
      border-color: $primary;
    }
  }

  .styled-placeholder::placeholder {
    color: #707070 !important;
    font-size: 15px;
    display: block;
  }

  .content {
    background: #fff;
    padding: 60px 15px;
    max-height: calc(100vh - 90px);
    overflow: scroll;
    text-align: center;

    .stepwizard {
      margin-top: 60px;

      .stepwizard-row {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style-type: none;
        padding: 0;
        margin: 0;

        .stepwizard-step {
          margin-right: 60px;

          &:last-child {
            margin-right: 0;
          }

          &:first-child .btn:before {
            display: none !important;
          }
          @media (max-width: 768px) {
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }
          }

          a.btn {
            background: $primary;
            border: 1px solid $primary;
            color: #fff;
            text-decoration: none;
            border-radius: 50%;
            padding: 0;
            width: 43px;
            height: 43px;
            position: relative;
            z-index: 99;
            box-shadow: none !important;
            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
              left: -61px;
              content: "";
              display: block;
              background: $primary;
              height: 4px;
              position: absolute;
              width: 60px;
              top: 50%;
              transform: translate(0, -50%);
            }
            @media (max-width: 768px) {
              height: 30px;
              width: 30px;

              &:before {
                left: -31px;
                content: "";
                display: block;
                background: $primary;
                height: 4px;
                position: absolute;
                width: 30px;
                top: 50%;
                transform: translate(0, -50%);
              }
            }

            &.active {
              background: #935ef0;
              border-color: #935ef0;

              &:before {
                left: -61px;
                content: "";
                display: block;
                background: $primary;
                height: 4px;
                position: absolute;
                width: 60px;
                top: 50%;
                transform: translate(0, -50%);
              }
            }
            @media (max-width: 768px) {
              &.active:before {
                left: -31px;
                content: "";
                display: block;
                background: $primary;
                height: 4px;
                position: absolute;
                width: 30px;
                top: 50%;
                transform: translate(0, -50%);
              }
            }

            &[disabled] {
              pointer-events: none;
              cursor: not-allowed;
              background: #dbdbdb;
              border-color: #dbdbdb;

              &:before {
                left: -61px;
                content: "";
                display: block;
                background: #dbdbdb;
                height: 4px;
                position: absolute;
                width: 60px;
                top: 50%;
                transform: translate(0, -50%);
              }
            }
            @media (max-width: 768px) {
              &[disabled]::before {
                left: -31px;
                content: "";
                display: block;
                background: #dbdbdb;
                height: 4px;
                position: absolute;
                width: 30px;
                top: 50%;
                transform: translate(0, -50%);
              }
            }
          }
        }
      }
    }

    .setup-content {
      display: none;
      text-align: left;
      margin: 0 auto;

      &.active {
        display: block;
      }

      .tab-title {
        color: $primary;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        transform: translate(0, -100px);
        font-family: $poppins;
        @media (max-width: 768px) {
          margin-top: -15px;
          font-size: 26px;
        }
      }

      .tab-sub-title {
        color: #707070;
        font-size: 14px;
        margin-bottom: 40px;
        text-align: center;

        .highlight {
          font-size: 20px !important;
          font-weight: 500;
        }
      }

      .input-field {
        margin-bottom: 40px;
        position: relative;

        &.col-sm-12 {
          .clear-icon {
            right: 10px;
          }
        }

        &.material-style {
          margin-bottom: 40px;
          margin-top: 0;

          input:valid + label {
            font-size: 0.63rem !important;
            top: -15px;
            pointer-events: none;
            font-weight: 400 !important;
          }

          .label {
            color: #8f8f8f !important;
            @media (max-width: 768px) {
              font-size: 18px !important;
            }
          }
        }

        .edit {
          font-size: 12px;
          margin-left: 10px;
        }

        .value {
          font-size: 22px;
          font-weight: 600;
          color: #122b28 !important;
          @media (max-width: 768px) {
            font-size: 18px;

            svg {
              height: 20px;
              width: 20px;
            }
          }
        }

        input,
        select,
        textarea {
          border: 0;
          border-bottom: 1px solid #dbdbdb;
          border-radius: 0;
          color: $color-text;
          padding: 0;

          &:focus {
            box-shadow: none;
            border-color: $primary;

            & + .clear-icon {
              opacity: 1;
            }
          }
        }

        textarea {
          border: 1px solid #dbdbdb;
          padding: 10px;
        }

        .help {
          position: absolute;
          right: 10px;
          top: 10px;
        }

        .clear-icon {
          color: $primary;
          position: absolute;
          top: 15px;
          right: 0;
          font-size: 1.25rem;
          text-decoration: none;
          opacity: 0;
        }

        .input-group {
          .clear-icon {
            top: 10px;
            z-index: 9;
          }
        }

        label {
          font-size: 10px;
          color: #8f8f8f;

          &.flex {
            display: flex;
            align-items: center;
          }

          .material-icons {
            color: $secondary;
            font-size: 18px;
            margin-left: 10px;
            text-decoration: none;
          }
        }

        ::placeholder {
          color: #dbdbdb;
          font-size: $size-text;
          font-weight: 400;
        }

        .error-message {
          color: $secondary;
          font-size: 10px;
          font-weight: 500;
          text-align: right;
          display: none;
        }

        .success-message {
          color: #36c02b;
          font-size: 10px;
          font-weight: 500;
          text-align: right;
          display: none;

          &.active {
            display: block;
          }
        }

        .helper-text {
          color: #707070;
          font-size: 10px;
          font-weight: 500;

          .single {
            display: inline-block;
            margin-right: 15px;
            font-size: 12px;
            margin-top: 10px;
          }
        }

        &.has-error {
          .dropdown-toggle,
          input {
            border-bottom: 1px solid $secondary;
          }

          .error-message {
            display: block;
          }
        }
      }

      .input-group input {
        margin-bottom: 0;
      }

      .dropdown-toggle {
        border: none;
        border-bottom: 1px solid #dbdbdb;
        border-radius: 0;
        color: #dbdbdb;

        &:hover {
          background: none;
          color: #dbdbdb;
        }

        &:focus {
          background: none;
          box-shadow: none;
        }

        &.unselected {
          white-space: nowrap !important;
          font-size: 22px !important;
          color: #8f8f8f !important;
          margin-bottom: -4px;
          @media (max-width: 768px) {
            font-size: 18px !important;
            margin-bottom: 6px;
            height: 44px;
          }
        }
      }

      .btn-next {
        font-size: 0.88rem;
        min-width: 150px;
        margin: 0 5px;

        &:focus {
          outline: 0;
          color: #fff;
          box-shadow: none !important;
        }

        &.btn-primary {
          background: $primary;
          border-color: $primary;
          @media (max-width: 768px) {
            margin: 20px 0 !important;
          }
        }

        &.btn-link {
          color: $primary;
          padding: 0;
          margin: 0;
        }

        &:disabled {
          background: #b4b4b4;
          border-color: #b4b4b4;
        }
      }

      .step-footer {
        text-align: center;
      }
    }
    // STEP 2
    .selected-b {
      #registration-step-2-a,
      #registration-step-2-c {
        display: none;
      }

      #registration-step-2-b {
        .desc {
          display: none;
        }
      }

      .after-select-info {
        display: block !important;
      }
    }

    .selected-c {
      #registration-step-2-a,
      #registration-step-2-b {
        display: none;
      }

      #registration-step-2-c {
        .desc {
          display: none;
        }
      }

      .after-select-info {
        display: block !important;
      }
    }

    .selected-a {
      #registration-step-2-b,
      #registration-step-2-c {
        display: none;
      }

      #registration-step-2-a {
        .desc {
          display: none;
        }
      }
    }

    .setup-content.step-2 {
      max-width: 900px;

      .selected {
        .single {
          height: auto;
        }

        .info-single {
          box-shadow: 0 0 0 4px #b69be4 !important;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 300px;
          padding: 24px 0 !important;
          cursor: pointer;
          height: auto;
          margin: 0 auto;

          .title {
            margin-bottom: 0;
          }
        }
      }

      .single {
        height: 100%;

        .title {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .icon {
            margin-right: 13px;
          }

          h2 {
            color: $primary;
            font-size: 24px;
            font-weight: bold;
          }

          p {
            color: $primary;
            font-size: 0.88rem;
            font-weight: bold;
            margin: 0;
          }
        }

        .after-select-info {
          display: none;
        }

        .desc {
          color: #707070;
          font-size: 0.88rem;
        }

        .info-single {
          padding: 26px 30px 0;
          box-shadow: 0 0 0 1px #707070;
          border-radius: 10px;
          cursor: pointer;
          height: 100%;

          &:hover {
            box-shadow: 0 0 0 4px #b69be4;
          }
        }

        .input-field {
          label {
            color: #8f8f8f;
            font-size: 10px;

            &.visibility-hidden {
              visibility: hidden;
            }
          }

          .form-control {
            min-height: 25px;

            &::placeholder {
              font-size: 22px;
              color: #b4b4b4;
              @media (max-width: 768px) {
                font-size: 18px;
              }
            }
          }
        }

        .info-text {
          color: $primary;
          text-align: center;
        }
      }

      .mobile-view {
        display: none;

        .single {
          padding: 26px 16px;

          .desc {
            margin: 0;
          }
        }

        .info-single {
          padding: 10px;
        }
      }
    }
    @media (max-width: 768px) {
      .web-view {
        display: none !important;
      }

      .mobile-view {
        display: block !important;
        margin: 0 auto;
      }
    }
  }
  // STEP: 3
  .step-3 {
    .tab-sub-title {
      margin-bottom: 70px;
    }
  }

  .virtual-local-number.show {
    display: none !important;
    @media (max-width: 768px) {
      display: block !important;
    }
  }
  @media (max-width: 768px) {
    .step-3-detail {
      display: none !important;
    }
  }

  .step-3-next {
    position: relative;
    @media (max-width: 768px) {
      .input-field .value {
        font-size: 18px;
      }
    }

    .toast {
      opacity: 1;

      .toast-body {
        position: fixed;
        bottom: 60px;
        left: 60px;
        border-radius: 5px;
        box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
        color: #fff;
        max-width: 295px;
        padding: 8px 16px;
        display: inline-flex;
        font-size: 20px;
        font-weight: 600;
        min-width: 270px;

        &.success {
          background-color: $primary;
        }

        &.warning {
          background-color: #e03737;
        }
        @media (max-width: 768px) {
          z-index: 99;
          left: 20px;
          bottom: 30px;
          padding: 5px 10px;
        }
      }
    }

    .availability-query {
      color: #707070;
      font-size: 14px;
      text-align: center;
      margin-top: 12px;
    }

    .forward-number-setup {
      width: 100%;
      @media (max-width: 768px) {
        padding-left: 20px;
      }

      .number-detail {
        display: none;
      }

      &.show-numberlist {
        .number-detail {
          display: block;
        }
      }
    }

    .backup-number-wrapper {
      width: 100%;

      .backup-number-detail {
        display: none;
      }

      &.show-detail {
        .backup-number-detail {
          display: block;

          .country-code-desktop {
            display: flex;
          }

          .country-code-mobile {
            display: none;
          }
          @media (max-width: 768px) {
            .country-code-desktop {
              display: none;
            }

            .country-code-mobile {
              display: block;
              white-space: nowrap;

              .edit {
                margin-left: 8px;
              }
            }
          }
        }

        .generated-numbers {
          .single-number {
            padding: 8px 16px;
            background-color: #fff;
            border-radius: 10px;
            border: 1px solid #dbdbdb;
            font-size: 20px;
            color: #dbdbdb;
            font-weight: 700;
            text-decoration: none;
            // &.focus {
            //   border: 4px solid $primary;
            //   box-shadow: none;
            //   color: $primary;
            // }
            &.active {
              border: 4px solid $primary;
              box-shadow: none;
              color: $primary;
            }

            input[type="radio"] {
              display: none !important;
            }
          }

          .refresh {
            font-size: 18px;
            margin-right: 10px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .setup-content .step-3,
  .setup-content .step-3-next {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    .custom-checkbox.card-single {
      height: 100%;
      cursor: pointer;
      @media (max-width: 768px) {
        padding: 20px 0;
      }

      .custom-control-label {
        padding: 16px 24px 20px;
        height: 100%;
        display: block;
        border-radius: 10px;
        text-align: center;
        min-height: 210px;
        width: 210px;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 0 1px #707070;

        &:hover {
          box-shadow: 0 0 0 4px #b69be4;
        }
        @media (max-width: 768px) {
          margin: 0 auto;
        }

        &:before {
          border-color: transparent;
          background-color: transparent;
        }

        .icons {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h3 {
          color: $primary;
          font-size: 20px;
          font-weight: bold;
          margin-top: 12px;
        }

        p {
          color: #707070;
          font-size: 12px;

          .color-primary {
            color: $primary;
          }
        }
      }

      .custom-control-inline {
        .zipcode {
          padding-left: 10px;
        }
      }
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label {
      box-shadow: 0 0 0 4px #b69be4;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none;
    }

    .step-3-detail {
      display: none;

      p {
        color: #707070;
        font-size: 0.88rem;
        line-height: 20px;
        text-align: center;
      }
    }

    .checked-option-1 {
      .step-3-option-2,
      .step-3-option-3 {
        opacity: 0.2;
      }

      .step-3-detail {
        display: block;
      }
    }

    .checked-option-2 {
      .step-3-option-1,
      .step-3-option-3 {
        opacity: 0.2;
      }

      .step-3-detail {
        display: block;
      }
    }

    .checked-option-3 {
      .step-3-option-1,
      .step-3-option-2 {
        opacity: 0.2;
      }

      .step-3-detail {
        display: block;
      }
    }

    .input-field {
      .flex {
        display: flex;
        align-items: center;
      }

      .refresh-icon {
        color: $primary;
        text-decoration: none;
      }
    }

    table {
      margin-bottom: 40px;

      td {
        padding: 0;
        font-size: 0.88rem;
        color: #707070;

        &:first-child {
          padding-right: 20px;
        }

        strong {
          color: #122b28;
          font-weight: 500;
        }
      }
    }
  }

  .setup-content.step-5 {
    .user-table {
      width: 100% !important;
      color: $poppins !important;

      td {
        padding-bottom: 36px;
      }

      .input-field {
        margin-bottom: 0;

        &.input-field-with-edit {
          .label {
            color: #122b28 !important;
          }
        }
      }

      .serial-number {
        vertical-align: bottom;
        color: #dbdbdb;
        font-size: 18px;
        font-family: $poppins;

        &.edit {
          color: #122b28;
          padding-bottom: 38px;
        }
      }

      .user-select {
        .dropdown-menu {
          padding: 0;

          .dropdown-item {
            display: flex;
            color: #122b28;
            font-weight: 600;

            &:active {
              color: #fff;
            }

            &:focus {
              color: #122b28;
              background-color: #fff;
            }

            img {
              height: 20px;
              margin-right: 10px;
            }
          }
        }

        .btn {
          text-align: left;
          width: 100%;

          &:focus {
            background-color: #fff;
          }
        }

        .input-group {
          max-width: 242px;
          width: 100%;
        }

        .input-group-prepend {
          width: 100%;
        }

        .btn-link {
          font-size: 20px;
        }
      }
      @media (max-width: 768px) {
        .single-row {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .bottom-action {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        margin: 40px 0;
      }
    }
  }
}

.registration-start {
  font-family: $poppins !important;

  &.show {
    display: flex;
    align-items: center;
  }

  .modal-dialog {
    max-width: 1120px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
  }

  .modal-content {
    width: 100%;
    padding: 70px 150px;
    max-height: 650px;
    overflow-y: auto;
    @media (max-width: 768px) {
      padding: 20px 16px;

      .modal-body {
        padding: 0;
      }

      .single {
        margin-bottom: 50px;
      }
    }
  }

  .tab-title {
    font-size: 30px;
    color: $primary;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .tab-sub-title {
    color: #707070;
    font-size: 14px;
    margin-bottom: 32px;

    .highlight {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  .single {
    max-width: 330px;
    margin: 20px auto;
    text-align: center;

    h2 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      text-align: left;

      .icon {
        margin-right: 12px;
        color: $primary;
      }

      span {
        color: $primary;
        font-size: 1.13rem;
        font-weight: bold;
      }
    }

    p {
      color: #707070;
      font-size: 0.88rem;
      line-height: 20px;
      text-align: left;
    }

    .btn {
      margin-top: 10px;
      padding: 8px 30px;
    }
  }
}

.r-15 {
  right: 15px !important;
}

.registration-footer {
  position: sticky;
  top: 100%;
  text-align: center;
  padding: 10px;
  background: linear-gradient(to right, #935ef0, #6c37c9);

  p {
    color: #fff;
    font-size: 12px;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
  }
}

.modal.register-error-info {
  .modal-dialog {
    border: none;
    position: fixed;
    bottom: 60px;
    left: 60px;
    border-radius: 5px;

    .modal-content {
      padding: 8px 16px;
      box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
      background: $primary;
      max-width: 300px;

      &.error {
        background: $secondary;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
      }
    }

    .modal-body {
      color: #fff;
      font-size: 1rem;
      line-height: 20px;
      font-weight: bold;
    }
  }
}
