// Hubs tab
.hubs-tab {
  background: $primary-light;
  height: 46px;
  position: absolute;
  width: 300px;
  z-index: 9999;

  &.hubs-tab-mobile-app .nav-tabs {
    background-color: #e1e1e1;

    .nav-link {
      svg {
        path {
          fill: #707070;
        }
      }

      &.active {
        svg {
          path {
            fill: #122b28;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    transition: ease-in-out 0.2s;

    .nav-tabs {
      border: 0;
      border-radius: 0;
      padding: 0;
    }
  }
  .nav-tabs {
    background: $primary-light;
    border-bottom: none;
    min-height: 47px;

    .nav-link {
      align-items: center;
      background: none;
      border: none;
      color: #fff;
      display: inline-flex;
      height: 46px;
      padding: 0 15px;
      &.active {
        background: none;
        border: none;
        svg path {
          fill: rgba(255, 255, 255, 1);
        }
      }
      svg path {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.message-view {
  &.left {
    @media (max-width: 991px) {
      left: 0;
      margin-right: 0;
      width: 100% !important;
    }
  }
}

// Panel tabs
.left-panel {
  .fixed-header {
    height: 47px;
    padding: 0;

    .nav-tabs {
      min-height: 47px;
      background: $primary-light;

      .nav-link {
        height: 46px;
        padding: 0 15px;
        color: #fff;
        display: inline-flex;
        align-items: center;
        background: none;
        border: none;
        &.active {
          background: none;
          border: none;
          svg path {
            fill: rgba(255, 255, 255, 1);
          }
        }
        svg path {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
