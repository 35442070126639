.chat-locked-button {
  align-items: center;
  background: rgba(112, 112, 112, 0.8);
  bottom: 0;
  color: #fff;
  display: flex;
  font-size: 16px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  &:hover {
    color: #fff;
  }
  &:active {
    color: #fff;
  }
  &:focus {
    color: #fff;
  }
  svg {
    height: 17px;
    margin-right: 10px;
    width: 17px;
  }
}
.alert + .chat-locked-button {
  top: 39px;
}
.locked-info-modal {
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    background: $primary;
    border: none;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
    min-width: 285px;
    padding: 40px 20px !important;
    text-align: center;
    width: auto;
  }
  .modal-body {
    padding: 1rem !important;
  }
  .icon {
    display: inline-block;
    margin-bottom: 15px;
    svg {
      height: 28px;
      width: 28px;
    }
  }
  p {
    margin: 0;
    color: white !important;
  }

  &.medium {
    .modal-content {
      background-color: #ffe577;
      color: #000;
    }
  }
  &.no-credit {
    .modal-content {
      background-color: #bfe9ff;
      color: #000;
    }
  }

  &.with-backdrop {
    background: rgba(0,0,0,0.7);
  }
}
