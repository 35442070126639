.new-group-modal {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    min-height: 450px;

    .group-type {
      border-bottom: 0;
      justify-content: center;
      margin-bottom: 10px;
      text-align: center;

      &.inner {
        justify-content: center;
        margin-bottom: 30px;

        .nav-item {
          flex-grow: unset;
        }

        a {
          height: 50px;
          width: 65px;
        }
      }

      a {
        align-items: center;
        border: 1px solid #dbdbdb;
        border-radius: 10px !important;
        display: inline-flex;
        flex-direction: column;
        height: 100px;
        justify-content: center;
        margin: 0;
        padding: 0;
        transition: border-width 0.3s ease-in-out;
        width: 140px;

        &:focus {
          background: none;
        }

        svg {
          path:not([fill="none"]) {
            fill: #dbdbdb;
          }
        }

        &:hover {
          border: 4px solid #b69be4;
        }

        &.active {
          border: 4px solid $primary;
          box-shadow: none;

          svg {
            path:not([fill="none"]) {
              fill: $primary;
            }
          }

          span {
            color: $primary;
          }
        }

        span {
          color: #dbdbdb;
          display: inline-block;
          font-weight: bold;
          margin-top: 10px;
        }
      }
    }

    .tag-input-wrapper {
      &.show-keywords {
        .input-field {
          display: block;
        }
      }

      .input-field {
        display: none;
      }
    }

    .tag-input {
      position: relative;

      &.has-value {
        .add-btn {
          opacity: 1;
        }
      }

      input {
        padding-right: 30px;
      }

      .add-btn {
        color: $primary;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .input-field {
      .label {
        color: #707070;
        font-size: 12px;
        margin-left: 5px;
      }

      .custom-select {
        color: #122b28;
        font-size: 1rem;
      }

      .form-control {
        color: #122b28;
        font-size: 1rem;
      }

      .filled-mode {
        align-items: center;
        border-bottom: 1px solid #dbdbdb;
        display: flex;
        height: 38px;

        .value-wrapper {
          color: #122b28;
          overflow: hidden;
          padding-left: 7px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .edit-icon {
          color: $primary;
          display: inline-block;
          padding: 7px;
        }

        .drop-arrow {
          font-size: 7px;
          margin-left: auto;
          margin-right: 7px;
        }
      }
    }

    .form-control {
      border-left: 0;
      border-right: 0;
      border-top: 0;
      padding-left: 5px;
      padding-right: 0;
    }

    .custom-switch {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      padding-left: 3rem;

      .info-icon {
        color: #122b28;
        font-size: 16px;
        margin-top: 3px;
      }
    }

    .custom-switch .custom-control-label::before {
      background: rgba(180, 180, 180, 0.4);
      border: 0;
      height: 16px;
      left: -3rem;
      width: 30px;
    }

    .custom-switch .custom-control-label::after {
      background: #fff;
      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.16));
      left: calc(-3rem + 2px);
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background: rgba($primary, 0.4);
    }

    .custom-switch
      .custom-control-input:checked
      ~ .custom-control-label::after {
      background: $primary;
    }
  }

  .custom-select {
    background: none;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    color: #122b28;
    padding-left: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .select-dropdown {
    align-items: center;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    height: 38px;
    &.show {
      .dropdown-toggle {
        opacity: 0;
        width: 0;
      }
      .input-field-inner {
        display: flex;
      }
    }
    .dropdown-toggle {
      color: #8f8f8f;
      font-size: 1rem;
      padding-left: 5px;
      transition: none;
      width: 100%;
      &::after {
        color: #122b28;
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    .dropdown-menu {
      width: 100%;
    }
    .input-field-inner {
      align-items: center;
      display: none;
      position: relative;
      width: 100%;
      .link-btn {
        position: absolute;
        right: 0;
      }
      .form-control {
        padding-right: 80px;
      }
    }
    .group-list {
      padding: 10px 15px;
      &.linked {
        h3 {
          color: #8f8f8f;
        }
        ul {
          a {
            color: #8f8f8f;
          }
        }
      }
      h3 {
        color: #122b28;
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
      }
      ul {
        list-style-type: none;
        margin: 0;
        margin-top: 10px;
        padding: 0;
        li {
          line-height: 30px;
        }
        a {
          align-items: center;
          color: #122b28;
          display: flex;
          font-size: 1rem;
          justify-content: space-between;
        }
      }
    }
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }
}
